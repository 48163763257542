import { ListSubheader, List } from "@material-ui/core";
import { useLocation, matchPath } from "react-router-dom";
import { useSelector } from "react-redux";
import { accountSelect } from "src/slices/account-slice";
import SidebarMenuItem from "./item";
import menuItems from "./items";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const SubMenuWrapper = styled(List)`
  &.MuiList-root {
    .MuiListItem-root {
      padding: 2px;
      padding-left: 16px;
      padding-right: 16px;

      .MuiButton-label {
        display: flex;
        position: relative;

        .MuiBadge-root {
          position: absolute;
          right: 16px;

          .MuiBadge-standard {
            background: #3f51b5;
            font-size: 0.75rem;
            color: #fff;
          }
        }
      }

      .MuiButton-root {
        display: flex;
        color: #242e6f;
        background-color: transparent;
        width: 100%;
        justify-content: flex-start;
        font-size: 0.875rem;
        padding-top: 4px;
        padding-bottom: 4px;

        .MuiButton-startIcon,
        .MuiButton-endIcon {
          transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

          .MuiSvgIcon-root {
            font-size: inherit;
            transition: none;
          }
        }

        .MuiButton-startIcon {
          font-size: 1.625rem;
          margin-right: 10px;
          color: #242e6f;
        }

        .MuiButton-endIcon {
          margin-left: auto;
          font-size: 1.375rem;
        }

        &.Mui-active,
        &:hover {
          background-color: #f6f8fb;
          color: #5569ff;

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            color: #5569ff;
          }
        }
      }

      &.Mui-children {
        flex-direction: column;
        line-height: 1;
      }

      .MuiCollapse-root {
        width: 100%;

        .MuiList-root {
          padding: 8px 0px;
        }

        .MuiListItem-root {
          padding: 1px;
          padding-left: 0;
          padding-right: 0;

          .MuiButton-root {
            font-size: 0.8125rem;
            padding: 4px 16px 4px 52px;

            &.Mui-active,
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
`;
function renderSidebarMenuItems({ items, path }) {
  return (
    <SubMenuWrapper>
      {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
    </SubMenuWrapper>
  );
}

function reduceChildRoutes({ ev, path, item }) {
  const key = item.name;

  if (item.items) {
    const open = matchPath(path, {
      path: item.link,
      exact: false,
    });

    ev.push(
      <SidebarMenuItem
        key={key}
        open={Boolean(open)}
        name={item.name}
        icon={item.icon}
        badge={item.badge}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items,
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        name={item.name}
        link={item.link}
        badge={item.badge}
        icon={item.icon}
      />
    );
  }

  return ev;
}

const MenuWrapper = styled(List)`
  margin-bottom: 8px;
  .MuiListSubheader-root {
    text-transform: uppercase;
    font-size: 0.8125rem;
    color: #6e759f;
    padding: 6px 16px;
    line-height: 1.4;
  }
`;

function SidebarMenu() {
  const location = useLocation();
  const { t } = useTranslation();
  const { privilege } = useSelector(accountSelect);

  return (
    <>
      {menuItems
        .filter((item) => item.privilege.includes(privilege))
        .map((section) => (
          <MenuWrapper
            key={section.heading}
            subheader={
              <ListSubheader disableSticky>{t(section.heading)}</ListSubheader>
            }
          >
            {renderSidebarMenuItems({
              items: section.items,
              path: location.pathname,
            })}
          </MenuWrapper>
        ))}
    </>
  );
}

export default SidebarMenu;
