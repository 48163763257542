import { useState, useRef, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import useRefMounted from "src/hooks/useRefMounted";
import devLog from "src/utils/dev-log";
import {
  selectNotifications,
  selectUserSettings,
  setNotificationsUnseen,
  setNotificationsRinged,
  setNotificationsCleared,
} from "src/slices/common-slice";
import { getNotifications, seenNotifications } from "src/utils/action-center";

import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  Popover,
  Tooltip,
  Link,
  Typography,
} from "@material-ui/core";
import { alpha } from "src/utils/helpers";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import NotificationsActiveTwoToneIcon from "@material-ui/icons/NotificationsActiveTwoTone";
import CancelIcon from "@material-ui/icons/Cancel";
import InfoNotification from "src/components/Notifications/info-notification";
import { MISC_SETTINGS, STATUS_TYPES } from "src/utils/info-base";

const UsableTheme = () => {
  const themeN = useTheme();
  return themeN;
};

const HeaderNotifications = () => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isMountedRef = useRefMounted();
  const [isOpen, setOpen] = useState(false);
  const [theData, setTheData] = useState([]);
  const [leftOver, setLeftOver] = useState(0);
  const { data, isLoading, unseen, ringed, cleared } =
    useSelector(selectNotifications);
  const { nfxSound } = useSelector(selectUserSettings);
  const audio = new Audio(MISC_SETTINGS.audioNfxURL);
  const handleSeenNtf = () => {
    if (theData.length) {
      seenNotifications();
      setLeftOver(0);
    }
  };
  const NotificationsBadge = styled(Badge)`
    .MuiBadge-badge {
      background-color: ${alpha(UsableTheme().palette.error.main, 0.1)};
      color: ${UsableTheme().palette.error.main};
      min-width: 16px;
      height: 16px;
      padding: 0;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: 0 0 0 1px ${alpha(UsableTheme().palette.error.main, 0.3)};
        content: "";
      }
    }
  `;

  const handleOpen = () => {
    setOpen(true);
    dispatch(setNotificationsUnseen(0));
    // handleSeenNtf();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSeenAllNfx = () => {
    dispatch(setNotificationsCleared(true)); // TODO
    dispatch(setNotificationsUnseen(0));
    handleClose();
    handleSeenNtf();
    setTheData([]);
  };
  useEffect(() => {
    if (isMountedRef.current) {
      if (!data.length) {
        getNotifications();
      }
    }
  }, []);
  useEffect(() => {
    if (isMountedRef.current) {
      if (cleared) {
        return setTheData([]);
      }
      if (data && data.length) {
        // let copyData = [...data];
        let copyData = data.filter(
          (ntf) => ntf.status === STATUS_TYPES.notification.UNSEEN
        );

        // setTheData(copyData.splice(0, 5));
        // setLeftOver(copyData);
        setTheData(copyData);
        setLeftOver(data.length - copyData.length);

        if (unseen && !ringed && nfxSound === "on") {
          try {
            // audio.play();
            dispatch(setNotificationsRinged(true));
          } catch (errorPlayingMsgNfx) {
            devLog("err playing Nfx", errorPlayingMsgNfx);
          }
        }
      }
    }
  }, [data, unseen]);

  return (
    <>
      <Tooltip arrow title={t("Notifications")}>
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={unseen}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <NotificationsActiveTwoToneIcon color="primary" />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          style={{ padding: 16 }}
          display="flex"
          justifyContent="space-between"
        >
          <Typography variant="h5">{t("Notifications")}</Typography>
          <IconButton onClick={handleClose} size="small">
            <CancelIcon style={{ marginRight: 8, color: "error" }} />
          </IconButton>
        </Box>
        <Divider />
        <List style={{ padding: 16 }}>
          {isLoading ? (
            <CircularProgress size="1rem" />
          ) : (
            theData.map((item) => (
              <Box key={item.nfxId}>
                <InfoNotification nfxData={item} />
                <Divider variant="inset" style={{ margin: 8 }} component="li" />
              </Box>
            ))
          )}
        </List>

        {data.length > 1 ? (
          <Button color="secondary" disabled fullWidth>
            {data.length} notifications ...
          </Button>
        ) : null}
        <Divider />
        {!pathname.includes("/notifications") ? (
          <Box style={{ margin: 8 }}>
            <Link variant="h5" component={RouterLink} to={"/notifications"}>
              <Button color="secondary" fullWidth>
                {t("View all notifications")}
              </Button>
            </Link>
          </Box>
        ) : null}

        {theData.length ? (
          <Box style={{ margin: 8 }}>
            <Button color="secondary" onClick={handleSeenAllNfx} fullWidth>
              {t("Clear all")}
            </Button>
          </Box>
        ) : null}
      </Popover>
    </>
  );
};

export default HeaderNotifications;
