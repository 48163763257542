import store from "src/store";
import { RESPONSE_TYPES, USER_TYPES } from "src/utils/info-base";
import axios from "src/utils/axios-interface";
import { getSocketClient } from "./socket-client";
import { setLogout, setUserInfo } from "src/slices/account-slice";

import {
  setSenderRateInfo,
  setSenderRateInfoLoading,
  setSenderAccountsInfo,
  setSenderAccountsInfoLoading,
  setSenderOrdersInfo,
  setSenderOrdersInfoLoading,
  setSenderDepositsInfo,
  setSenderDepositsInfoLoading,
  setSenderDashboard,
  setSenderDashboardLoading,
  setSenderPayments,
  setSenderPaymentsLoading,
  setSenderPaymentStat,
  setSenderPaymentStatLoading,
  emptyAllSenderSlice,
} from "src/slices/sender-slice";
import {
  setDistOrdersInfo,
  setDistOrdersInfoLoading,
  setDistDepositCurr,
  setDistDepositCurrLoading,
  setDistDepositsInfo,
  setDistDepositsInfoLoading,
  setDistDashboard,
  setDistDashboardLoading,
  setDistPayments,
  setDistPaymentsLoading,
  setDistPaymentStat,
  setDistPaymentStatLoading,
  setDistBalanceInfo,
  setDistBalanceInfoLoading,
  emptyAllDistSlice,
} from "src/slices/dist-slice";
import {
  setActiveCountries,
  setSimpleRate,
  setSimpleRateLoading,
  setActiveCountriesLoading,
  setNotifications,
  setNotificationsLoading,
  setMessages,
  setMessagesLoading,
  setBeneficiaryCountries,
  setBeneficiaryCountriesLoading,
  setBenCoAddCurr,
  setBenCoAddCurrLoading,
  setUserSettings,
  emptyAllCommonSlice,
  emptyChats,
  setChatIsRegistered,
  setKvPair,
  setKvPairLoading,
} from "src/slices/common-slice";

import {
  setOrdersInfo,
  setOrdersInfoLoading,
  setDashboardInfo,
  setDashboardInfoLoading,
  setAgentRateInfo,
  setAgentRateInfoLoading,
  setDepositsInfo,
  setDepositsInfoLoading,
  setPaymentsInfo,
  setPaymentsInfoLoading,
  setUsersList,
  setUsersListLoading,
  setSelectedSingleUser,
  setSelectedSingleUserLoading,
  setSelectedOrders,
  setSelectedOrdersLoading,
  setOrderApprovals,
  setOrderApprovalsLoading,
  setDepositApprovals,
  setDepositApprovalsLoading,
  setCountriesInfo,
  setCountriesInfoLoading,
  setRateInfo,
  setRateInfoLoading,
  setRules,
  setRulesLoading,
  setRulesConverted,
  setRulesConvertedLoading,
  setTelMessages,
  setTelMessagesLoading,
  setProfitPercent,
  setProfitPercentLoading,
  setSalaryGroups,
  setSalaryGroupsLoading,
  emptyAllOfficeSlice,
} from "src/slices/office-slice";
import devLog from "./dev-log";
const socket = getSocketClient();
const onResError = (msg, payload) => {
  devLog(msg, payload);
  return false;
};
const onResSuccess = () => {
  return true;
};
const getUserType = () => {
  return store.getState().accountData.userType;
};
const getReportPrefix = () => {
  let theUserType = getUserType();
  if (theUserType === USER_TYPES.ADMIN || theUserType === USER_TYPES.OFFICE) {
    return "/ar";
  }
  return "/r";
};
// axios fetchers

// Admin Functions
export const getOrderApprovals = async () => {
  store.dispatch(setOrderApprovalsLoading(true)); // TODO set all others loading to top of the function
  try {
    const res = await axios.post(getReportPrefix(), {
      type: "order-approvals",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setOrderApprovals(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getDepositApprovals = async () => {
  try {
    const res = await axios.post(getReportPrefix(), {
      type: "deposit-approvals",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setDepositApprovalsLoading(true));
      store.dispatch(setDepositApprovals(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getUsersList = async () => {
  try {
    const res = await axios.post(getReportPrefix(), {
      type: "users-basic",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setUsersListLoading(true));
      store.dispatch(setUsersList(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getSelectedSingleUser = async (userId) => {
  store.dispatch(setSelectedSingleUserLoading(true));
  store.dispatch(setSelectedOrdersLoading(true));
  store.dispatch(setSelectedOrders([]));

  try {
    const res = await axios.post(getReportPrefix(), {
      type: "single-user-plus",
      userId: userId,
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setSelectedSingleUser(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getOrdersInfo = async () => {
  try {
    const res = await axios.post(getReportPrefix(), {
      type: "orders-info",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setOrdersInfoLoading(true));
      store.dispatch(setOrdersInfo(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getDepositsInfo = async () => {
  try {
    const res = await axios.post(getReportPrefix(), {
      type: "deposits-info",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setDepositsInfoLoading(true));
      store.dispatch(setDepositsInfo(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getAgentRateInfo = async () => {
  try {
    const res = await axios.post(getReportPrefix(), {
      type: "agent-simple-rate-info",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setAgentRateInfoLoading(true));
      store.dispatch(setAgentRateInfo(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getDashboardInfo = async () => {
  try {
    const res = await axios.post(getReportPrefix(), {
      type: "dashboard",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setDashboardInfoLoading(true));
      store.dispatch(setDashboardInfo(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getCountriesInfo = async () => {
  try {
    const res = await axios.post(getReportPrefix(), {
      type: "co-info",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setCountriesInfoLoading(true));
      store.dispatch(setCountriesInfo(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getRateInfo = async () => {
  try {
    const res = await axios.post(getReportPrefix(), {
      type: "rate-info",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setRateInfoLoading(true));
      store.dispatch(setRateInfo(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getPaymentsInfo = async () => {
  try {
    const res = await axios.post(getReportPrefix(), {
      type: "payments-info",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setPaymentsInfoLoading(true));
      store.dispatch(setPaymentsInfo(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getRules = async () => {
  store.dispatch(setRulesLoading(true));
  store.dispatch(setRulesConvertedLoading(true));

  try {
    const res = await axios.post(getReportPrefix(), {
      type: "rules",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setRules(res.data.payload));

      if (res.data.payload && res.data.payload.length) {
        let conV = {};
        res.data.payload.forEach((rl) => {
          conV[rl.kvKey] = rl.kvValue;
        });
        store.dispatch(setRulesConverted(conV));
      }

      store.dispatch(setRulesConvertedLoading(false));
      store.dispatch(setRulesLoading(false));

      return onResSuccess();
    } else {
      store.dispatch(setRulesConvertedLoading(false));
      store.dispatch(setRulesLoading(false));

      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    store.dispatch(setRulesConvertedLoading(false));
    store.dispatch(setRulesLoading(false));

    return onResError("error", error);
  }
};
export const getProfitPercent = async () => {
  try {
    const res = await axios.post(getReportPrefix(), {
      type: "profitPercent",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setProfitPercentLoading(true));
      store.dispatch(setProfitPercent(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getSalaryGroups = async () => {
  try {
    const res = await axios.post(getReportPrefix(), {
      type: "salaryGroups",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setSalaryGroupsLoading(true));
      store.dispatch(setSalaryGroups(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
// Sender Functions
export const getSenderOrdersInfo = async () => {
  try {
    const res = await axios.post("/r", {
      type: "s-orders-info",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setSenderOrdersInfoLoading(true));
      store.dispatch(setSenderOrdersInfo(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getSenderRateInfo = async () => {
  try {
    const res = await axios.post("/r", {
      type: "s-rate-info",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setSenderRateInfoLoading(true));
      store.dispatch(setSenderRateInfo(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getSenderAccountsInfo = async () => {
  try {
    const res = await axios.post("/r", {
      type: "s-accounts-info",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setSenderAccountsInfoLoading(true));
      store.dispatch(setSenderAccountsInfo(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getSenderDashboard = async () => {
  try {
    const res = await axios.post("/r", {
      type: "s-dashboard",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setSenderDashboardLoading(true));
      store.dispatch(setSenderDashboard(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getSenderDepositsInfo = async () => {
  try {
    const res = await axios.post("/r", {
      type: "s-deposits-info",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setSenderDepositsInfoLoading(true));
      store.dispatch(setSenderDepositsInfo(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getSenderPayments = async () => {
  try {
    const res = await axios.post("/r", {
      type: "s-payments",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setSenderPaymentsLoading(true));
      store.dispatch(setSenderPayments(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getSenderPaymentStat = async () => {
  try {
    const res = await axios.post("/r", {
      type: "s-payment-stat",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setSenderPaymentStatLoading(true));
      store.dispatch(setSenderPaymentStat(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
// Dist Functions
export const getDistOrdersInfo = async () => {
  try {
    const res = await axios.post("/r", {
      type: "d-orders-info",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setDistOrdersInfoLoading(true));
      store.dispatch(setDistOrdersInfo(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getDistDepositsInfo = async () => {
  try {
    const res = await axios.post("/r", {
      type: "d-deposits-info",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setDistDepositsInfoLoading(true));
      store.dispatch(setDistDepositsInfo(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getDistDashboard = async () => {
  try {
    const res = await axios.post("/r", {
      type: "d-dashboard",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setDistDashboardLoading(true));
      store.dispatch(setDistDashboard(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getDistDepositCurrencies = async () => {
  try {
    const res = await axios.post("/r", {
      type: "d-deposit-curr",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setDistDepositCurrLoading(true));
      store.dispatch(setDistDepositCurr(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getDistPayments = async () => {
  try {
    const res = await axios.post("/r", {
      type: "d-payments",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setDistPaymentsLoading(true));
      store.dispatch(setDistPayments(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getDistPaymentStat = async () => {
  try {
    const res = await axios.post("/r", {
      type: "d-payment-stat",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setDistPaymentStatLoading(true));
      store.dispatch(setDistPaymentStat(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getDistBalanceInfo = async () => {
  try {
    const res = await axios.post("/r", {
      type: "d-balance-info",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setDistBalanceInfoLoading(true));
      store.dispatch(setDistBalanceInfo(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};

// Common Functions
export const getActiveCountries = async () => {
  try {
    const res = await axios.post("/r", {
      type: "co-active-info",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setActiveCountriesLoading(true));
      store.dispatch(setActiveCountries(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getSimpleRate = async () => {
  try {
    const res = await axios.post("/r", {
      type: "simple-rate",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setSimpleRateLoading(true));
      store.dispatch(setSimpleRate(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getBenCoAddCurr = async () => {
  try {
    const res = await axios.post("/r", {
      type: "ben-co-add-cu",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setBenCoAddCurrLoading(true));
      store.dispatch(setBenCoAddCurr(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getBeneficiaryCountryCity = async () => {
  try {
    const res = await axios.post("/r", {
      type: "ben-co-ci-info",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setBeneficiaryCountriesLoading(true));
      store.dispatch(setBeneficiaryCountries(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getNotifications = async () => {
  try {
    const res = await axios.post(getReportPrefix(), {
      type: "nfx",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setNotificationsLoading(true));
      store.dispatch(setNotifications(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getMessages = async () => {
  try {
    const res = await axios.post(getReportPrefix(), {
      type: "msg",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setMessagesLoading(true));
      store.dispatch(setMessages(res.data.payload));
      store.dispatch(emptyChats());

      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getTelMessages = async () => {
  try {
    const res = await axios.post(getReportPrefix(), {
      type: "tel-msg",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setTelMessagesLoading(true));
      store.dispatch(setTelMessages(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getUnseenMessages = async () => {
  try {
    const res = await axios.post(getReportPrefix(), {
      type: "msg-unseen",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setMessagesLoading(true));
      store.dispatch(setMessages(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getUserSettings = async () => {
  try {
    const res = await axios.post("/r", {
      type: "user-settings",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setUserSettings(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getUserInfo = async () => {
  try {
    const res = await axios.post("/r", {
      type: "user-info",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setUserInfo(res.data.payload));
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const getKvPair = async (kvKey) => {
  try {
    const res = await axios.post("/r", {
      type: "kv",
      kvKey: kvKey,
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      store.dispatch(setKvPairLoading(true));
      store.dispatch(
        setKvPair({
          kvKey: kvKey,
          kvValue: res.data.payload.kvValue,
        })
      );
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const seenNotifications = async () => {
  let usrTyp = getUserType();

  try {
    const res = await axios.post("/nfx-seen", {
      toUser:
        usrTyp === USER_TYPES.ADMIN || usrTyp === USER_TYPES.OFFICE
          ? "office"
          : "no",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      //
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const seenMessages = async (currentUser) => {
  let usrTyp = getUserType();
  if (!currentUser) {
    return;
  }
  try {
    const res = await axios.post("/msg-seen", {
      toUser:
        usrTyp === USER_TYPES.ADMIN || usrTyp === USER_TYPES.OFFICE
          ? USER_TYPES.OFFICE
          : "no",
      fromUser:
        usrTyp === USER_TYPES.ADMIN || usrTyp === USER_TYPES.OFFICE
          ? currentUser
          : "no",
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      //
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const seenChats = async (seenChats, decodedUserId) => {
  if (!seenChats.length) {
    return;
  }
  let filteredChats = [];

  seenChats.forEach((chat) => {
    if (chat.fromUser !== decodedUserId) {
      filteredChats.push({
        chatId: chat.chatId,
        toUser: chat.toUser,
      });
    }
  });

  if (!filteredChats.length) {
    return;
  }
  try {
    const res = await axios.post("/chat-seen", {
      chats: filteredChats,
    });

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      //
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const sendLogOut = async () => {
  try {
    const res = await axios.post("/logout");

    if (res.data.message === RESPONSE_TYPES.actions.SUCCESS) {
      //
      return onResSuccess();
    } else {
      return onResError(res.data.message, res.data.payload);
    }
  } catch (error) {
    return onResError("error", error);
  }
};
export const closeAccount = async () => {
  try {
    const res = await axios.post("/close");
  } catch (error) {}
  store.dispatch(setLogout());
  emptySlices();
  window.localStorage.clear();
  window.sessionStorage.clear();
  window.location.replace("http://www.google.com");
  window.location.href = "http://www.google.com";
};
export const emptySlices = () => {
  store.dispatch(emptyAllSenderSlice());
  store.dispatch(emptyAllDistSlice());
  store.dispatch(emptyAllOfficeSlice());
  store.dispatch(emptyAllCommonSlice());
};
export const logOut = () => {
  if (socket) {
    socket.emit("unReg", (res) => {
      if (res === "success") store.dispatch(setChatIsRegistered(false));
    });
  }

  store.dispatch(setLogout());
  emptySlices();
  sendLogOut();
};
