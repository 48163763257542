// v 1.4
import { createRef } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import routes, { renderRoutes } from "./router";
import { SnackbarProvider } from "notistack";

import ScrollToTop from "./utils/ScrollToTop";
import FeedBase from "./contexts/FeedBase";
// import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import AdapterDateFns from "@date-io/date-fns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import ThemeProvider from "./theme/ThemeProvider";
import { CssBaseline, IconButton } from "@material-ui/core";
import CloseTwoTone from "@material-ui/icons/CloseTwoTone";

const history = createBrowserHistory();

const App = () => {
  const notistackRef = createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          ref={notistackRef}
          action={(key) => (
            <IconButton onClick={onClickDismiss(key)}>
              <CloseTwoTone />{" "}
            </IconButton>
          )}
        >
          <Router history={history}>
            <ScrollToTop />
            <CssBaseline />
            <FeedBase> {renderRoutes(routes)} </FeedBase>{" "}
          </Router>{" "}
        </SnackbarProvider>{" "}
      </LocalizationProvider>{" "}
    </ThemeProvider>
  );
};

export default App;
