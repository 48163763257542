import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { accountSelect } from "src/slices/account-slice";

import { logOut } from "src/utils/action-center";
import ThemeToggle from "src/layouts/SidebarLayout/Header/Buttons/Settings/ThemeToggle/theme-toggle";

import {
  Avatar,
  Box,
  Button,
  IconButton,
  Divider,
  Hidden,
  lighten,
  Popover,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import ExpandMoreTwoToneIcon from "@material-ui/icons/ExpandMoreTwoTone";
import SettingsTwoToneIcon from "@material-ui/icons/SettingsTwoTone";
import LockOpenTwoToneIcon from "@material-ui/icons/LockOpenTwoTone";
import CancelIcon from "@material-ui/icons/Cancel";
import { USER_TYPES } from "src/utils/info-base";

const UserBoxButton = styled(Button)`
  padding-left: 8px;
  padding-right: 8px;
`;

const MenuUserBox = styled(Box)`
  /* background: #f5f5f5; */
  padding: 16px;
`;

const UserBoxText = styled(Box)`
  text-align: left;
  padding-left: 8px;
`;

const UserBoxLabel = styled(Typography)`
  font-weight: 700;
  color: #f50057;
  display: block;
`;

const UserBoxDescription = styled(Typography)`
  color: ${lighten("#f50057", 0.5)};
`;

function HeaderUserbox() {
  const { t } = useTranslation();
  const { fullName, userType } = useSelector(accountSelect);
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    logOut();
    handleClose();
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <SettingsTwoToneIcon />
        {/* <Avatar variant="rounded" alt={fullName}></Avatar> */}
        {/*   <Hidden mdDown>
         <UserBoxText>
            <UserBoxLabel variant="body1">{fullName}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {userType.toUpperCase()}
            </UserBoxDescription>
          </UserBoxText> 
        </Hidden>*/}
        <Hidden smDown>
          <ExpandMoreTwoToneIcon style={{ marginLeft: 8 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "space-between",
          }}
        >
          <MenuUserBox style={{ minWidth: 210 }} display="flex">
            <Avatar variant="rounded" alt={fullName} />

            <UserBoxText>
              <UserBoxLabel variant="body1">{fullName}</UserBoxLabel>
              <UserBoxDescription variant="body2">
                {userType === USER_TYPES.ADMIN
                  ? "Admin"
                  : userType === USER_TYPES.OFFICE
                  ? "Office"
                  : "Agent"}
              </UserBoxDescription>
            </UserBoxText>
          </MenuUserBox>
          <Box style={{ margin: 8 }}>
            <IconButton onClick={handleClose}>
              <CancelIcon style={{ marginRight: 1, color: "error" }} />
            </IconButton>
          </Box>
        </div>

        <Divider style={{ marginBottom: 8 }} />
        <ThemeToggle />
        {/* <LanguageToggle handleClose={handleClose} /> */}

        <Divider />
        <Box style={{ margin: 8 }}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon style={{ marginRight: 8 }} />
            {t("Sign out")}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
