import {
  useEffect
} from "react";
import NProgress from "nprogress";
import {
  Box,
  CircularProgress
} from "@material-ui/core";

const SuspenseLoader = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return ( <
    Box style = {
      {
        width: "100%",
        height: "100%"
      }
    }
    display = "flex"
    alignItems = "center"
    justifyContent = "center" >
    <
    CircularProgress style = {
      {
        margin: 24
      }
    }
    size = {
      64
    }
    disableShrink thickness = {
      3
    }
    />   <
    /Box>
  );
};

export default SuspenseLoader;