import {
  createSlice
} from "@reduxjs/toolkit";
const baseStructureArray = {
  isLoading: true,
  data: [],
};
const baseStructureObject = {
  isLoading: true,
  data: {},
};
export const officeSlice = createSlice({
  name: "office",
  initialState: {
    usersList: {
      ...baseStructureArray
    },
    selectedSingleUser: {
      ...baseStructureObject
    },
    selectedOrders: {
      ...baseStructureArray
    },
    rules: {
      ...baseStructureArray
    },
    rulesConverted: {
      ...baseStructureObject
    },
    orderApprovals: {
      ...baseStructureArray
    },
    depositApprovals: {
      ...baseStructureArray
    },
    ordersInfo: {
      ...baseStructureArray
    },
    depositsInfo: {
      ...baseStructureArray
    },
    paymentsInfo: {
      ...baseStructureArray
    },
    telMessages: {
      ...baseStructureArray
    },
    agentRateInfo: {
      ...baseStructureObject
    },
    countriesInfo: {
      ...baseStructureArray
    },
    profitPercent: {
      ...baseStructureArray
    },
    salaryGroups: {
      ...baseStructureArray
    },
    rateInfo: {
      isLoading: true,
      data: {
        activeCountries: [],
        workingCurrencies: [],
        rateInfo: {},
      },
    },
    dashboardInfo: {
      isLoading: true,
      data: {
        orders: {
          countAllOrders: "",
          countSentOrders: "",
          countProgressOrders: "",
          countCancelledOrders: "",
        },
        topOrdCo: "",
        deposits: {
          countAllDeposits: "",
          countApprovedDeposits: "",
          countProgressDeposits: "",
          countCancelledDeposits: "",
        },
      },
    },
  },
  reducers: {
    setOrdersInfo: (state, action) => {
      state.ordersInfo = {
        data: action.payload,
        isLoading: false,
      };
    },
    setOrdersInfoLoading: (state, action) => {
      state.ordersInfo.isLoading = action.payload;
    },
    setRules: (state, action) => {
      state.rules = {
        data: action.payload,
        isLoading: false,
      };
    },
    setRulesLoading: (state, action) => {
      state.rules.isLoading = action.payload;
    },
    setRulesConverted: (state, action) => {
      state.rulesConverted = {
        data: action.payload,
        isLoading: false,
      };
    },
    setRulesConvertedLoading: (state, action) => {
      state.rulesConverted.isLoading = action.payload;
    },
    setDashboardInfo: (state, action) => {
      state.dashboardInfo = {
        data: action.payload,
        isLoading: false,
      };
    },
    setDashboardInfoLoading: (state, action) => {
      state.dashboardInfo.isLoading = action.payload;
    },
    setAgentRateInfo: (state, action) => {
      state.agentRateInfo = {
        data: action.payload,
        isLoading: false,
      };
    },
    setAgentRateInfoLoading: (state, action) => {
      state.agentRateInfo.isLoading = action.payload;
    },
    setDepositsInfo: (state, action) => {
      state.depositsInfo = {
        data: action.payload,
        isLoading: false,
      };
    },
    setDepositsInfoLoading: (state, action) => {
      state.depositsInfo.isLoading = action.payload;
    },
    setPaymentsInfo: (state, action) => {
      state.paymentsInfo = {
        data: action.payload,
        isLoading: false,
      };
    },
    setPaymentsInfoLoading: (state, action) => {
      state.paymentsInfo.isLoading = action.payload;
    },
    setTelMessages: (state, action) => {
      state.telMessages = {
        data: action.payload,
        isLoading: false,
      };
    },
    setTelMessagesLoading: (state, action) => {
      state.paymentsInfo.isLoading = action.payload;
    },
    setUsersList: (state, action) => {
      state.usersList = {
        data: action.payload,
        isLoading: false,
      };
    },
    setUsersListLoading: (state, action) => {
      state.usersList.isLoading = action.payload;
    },
    setSelectedSingleUser: (state, action) => {
      state.selectedSingleUser = {
        data: action.payload,
        isLoading: false,
      };
    },
    setSelectedSingleUserPart: (state, action) => {
      state.selectedSingleUser.data[action.payload.part] = action.payload.partData;
    },
    setSelectedSingleUserLoading: (state, action) => {
      state.selectedSingleUser.isLoading = action.payload;
    },
    setSelectedOrders: (state, action) => {
      state.selectedOrders = {
        data: action.payload,
        isLoading: false,
      };
    },
    setSelectedOrdersLoading: (state, action) => {
      state.selectedOrders.isLoading = action.payload;
    },
    
    setOrderApprovals: (state, action) => {
      state.orderApprovals = {
        data: action.payload,
        isLoading: false,
      };
    },
    setOrderApprovalsLoading: (state, action) => {
      state.orderApprovals.isLoading = action.payload;
    },
    setDepositApprovals: (state, action) => {
      state.depositApprovals = {
        data: action.payload,
        isLoading: false,
      };
    },
    setDepositApprovalsLoading: (state, action) => {
      state.depositApprovals.isLoading = action.payload;
    },
    setCountriesInfo: (state, action) => {
      state.countriesInfo = {
        data: action.payload,
        isLoading: false,
      };
    },
    setCountriesInfoLoading: (state, action) => {
      state.countriesInfo.isLoading = action.payload;
    },
    setRateInfo: (state, action) => {
      state.rateInfo = {
        data: action.payload,
        isLoading: false,
      };
    },
    setRateInfoLoading: (state, action) => {
      state.rateInfo.isLoading = action.payload;
    },
    setProfitPercent: (state, action) => {
      state.profitPercent = {
        data: action.payload,
        isLoading: false,
      };
    },
    setProfitPercentLoading: (state, action) => {
      state.profitPercent.isLoading = action.payload;
    },
    setSalaryGroups: (state, action) => {
      state.salaryGroups = {
        data: action.payload,
        isLoading: false,
      };
    },
    setSalaryGroupsLoading: (state, action) => {
      state.salaryGroups.isLoading = action.payload;
    },
    emptyAllOfficeSlice: (state) => {
      state.usersList = {
        ...baseStructureArray
      };
      state.selectedSingleUser = {
        ...baseStructureObject
      };
      state.selectedOrders = {
        ...baseStructureArray
      };
      state.rules = {
        ...baseStructureArray
      };
      state.rulesConverted = {
        ...baseStructureObject
      };
      state.orderApprovals = {
        ...baseStructureArray
      };
      state.depositApprovals = {
        ...baseStructureArray
      };
      state.depositsInfo = {
        ...baseStructureArray
      };
      state.paymentsInfo = {
        ...baseStructureArray
      };
      state.telMessages = {
        ...baseStructureArray
      };
      state.ordersInfo = {
        ...baseStructureArray
      };
      state.agentRateInfo = {
        ...baseStructureObject
      };
      state.countriesInfo = {
        ...baseStructureArray
      };
      state.rateInfo = {
        isLoading: true,
        data: {
          activeCountries: [],
          workingCurrencies: [],
          rateInfo: {},
        },
      };
      state.dashboardInfo = {
        isLoading: true,
        data: {
          orders: {
            countAllOrders: "",
            countSentOrders: "",
            countProgressOrders: "",
            countCancelledOrders: "",
          },
          topOrdCo: "",
          deposits: {
            countAllDeposits: "",
            countApprovedDeposits: "",
            countProgressDeposits: "",
            countCancelledDeposits: "",
          },
        },
      };
      state.profitPercent = {
        ...baseStructureArray
      };
      state.salaryGroups = {
        ...baseStructureArray
      };
    },
  },
});

export const {
  setOrdersInfo,
  setOrdersInfoLoading,
  setDashboardInfo,
  setDashboardInfoLoading,
  setAgentRateInfo,
  setAgentRateInfoLoading,
  setDepositsInfo,
  setDepositsInfoLoading,
  setPaymentsInfo,
  setPaymentsInfoLoading,
  setTelMessages,
  setTelMessagesLoading,
  setUsersList,
  setUsersListLoading,
  setSelectedSingleUser,
  setSelectedSingleUserPart,
  setSelectedSingleUserLoading,
  setSelectedOrders,
  setSelectedOrdersLoading,
  setOrderApprovals,
  setOrderApprovalsLoading,
  setDepositApprovals,
  setDepositApprovalsLoading,
  setCountriesInfo,
  setCountriesInfoLoading,
  setRateInfo,
  setRateInfoLoading,
  setRules,
  setRulesLoading,
  setRulesConverted,
  setRulesConvertedLoading,
  setProfitPercent,
  setProfitPercentLoading,
  setSalaryGroups,
  setSalaryGroupsLoading,
  emptyAllOfficeSlice,
} = officeSlice.actions;

export const selectOrderApprovals = (state) => state.officeData.orderApprovals;

export const selectRules = (state) => state.officeData.rules;

export const selectRulesConverted = (state) => state.officeData.rulesConverted;

export const selectDashboardInfo = (state) => state.officeData.dashboardInfo;

export const selectOrdersInfo = (state) => state.officeData.ordersInfo;

export const selectDepositsInfo = (state) => state.officeData.depositsInfo;

export const selectPaymentsInfo = (state) => state.officeData.paymentsInfo;

export const selectTelMessages = (state) => state.officeData.telMessages;

export const selectAgentRateInfo = (state) => state.officeData.agentRateInfo;

export const selectCountriesInfo = (state) => state.officeData.countriesInfo;

export const selectRateInfo = (state) => state.officeData.rateInfo;

export const selectProfitPercent = (state) => state.officeData.profitPercent;

export const selectSalaryGroups = (state) => state.officeData.salaryGroups;

export const selectDepositApprovals = (state) =>
  state.officeData.depositApprovals;

export const selectUsersList = (state) => state.officeData.usersList;

export const selectSelectedSingleUser = (state) => state.officeData.selectedSingleUser;

export const selectSelectedOrders = (state) => state.officeData.selectedOrders;

export default officeSlice.reducer;