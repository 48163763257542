import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { getUsersList } from "src/utils/action-center";
import { selectUsersList } from "src/slices/office-slice";
import useRefMounted from "src/hooks/useRefMounted";

import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

const AdvancedUsersListAutoComplete = (props) => {
  const isMountedRef = useRefMounted();
  const usersListRdx = useSelector(selectUsersList);
  const usersList = usersListRdx.data;
  const history = useHistory();

  const [filteredList, setFilteredList] = useState([]);

  const handleChange = (v) => {
    if (props.returnFullAction) {
      let toReturn = v && v.userId ? v : {}; // important
      props.returnFullAction(toReturn);
    } else if (props.returnAction) {
      let toReturn = v && v.userId ? v.userId : null; // important
      props.returnAction(toReturn);
    } else {
      if (v && v.userId) history.push(`${props.mainLink}${v.userId}`);
    }

    if (props.returnFullName) {
      let theFullName = "";
      theFullName = v && v.fullName ? v.fullName : "";
      props.returnFullName(theFullName);
    }
  };

  useEffect(() => {
    if (isMountedRef.current) {
      if (!usersList.length) {
        getUsersList();
      }
    }
  }, []);

  useEffect(() => {
    if (isMountedRef.current) {
      let finalFilter = [];
      if (props.filterTypeNot && props.filterTypeNot.length) {
        let tempFilterNotContainer = [...usersList];

        props.filterTypeNot.forEach((ftnAr) => {
          tempFilterNotContainer = tempFilterNotContainer.filter(
            (item) => item[ftnAr.key] !== ftnAr.value
          );
        });
        finalFilter = [...tempFilterNotContainer];
      }
      if (props.filterType && props.filterType.length) {
        let tempFilterContainer = [];

        props.filterType.forEach((ftnAr) => {
          let tempFilter = usersList.filter(
            (item) => item[ftnAr.key] === ftnAr.value
          );
          tempFilterContainer = [...tempFilterContainer, ...tempFilter];
        });
        finalFilter = [...finalFilter, ...tempFilterContainer];
      }
      setFilteredList(finalFilter);
    }
  }, [props.filterTypeNot, props.filterType, usersList, isMountedRef]);
  return (
    <Box justifyContent="center">
      {usersListRdx.isLoading ? (
        <CircularProgress size={20} color="secondary" />
      ) : (
        <>
          <Autocomplete
            options={filteredList}
            getOptionLabel={(option) =>
              `${option.fullName} (${option.code}) - ${option.country} `
            }
            fullWidth
            color="secondary"
            onChange={(e, v) => handleChange(v)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={props.label ? props.label : "Search"}
                // style={{ color: "white" }}
                fullWidth
                // for formik
                variant={props.variant ? props.variant : "outlined"}
              />
            )}
          />
        </>
      )}
    </Box>
  );
};
export default AdvancedUsersListAutoComplete;
