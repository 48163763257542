import { useContext, useState } from "react";
import { ThemeContext } from "src/theme/ThemeProvider";
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const ThemeToggleWrapper = styled(Box)`
  /* background: #fbfbfb; */
  padding: 16px;
`;

const ThemeToggle = (_props) => {
  const setThemeName = useContext(ThemeContext);
  const { t } = useTranslation();
  const curThemeName = localStorage.getItem("appTheme") || "PureLightTheme";

  const [theme, setTheme] = useState(curThemeName);

  const changeTheme = (event) => {
    setTheme(event.target.value);
    setThemeName(event.target.value);
  };

  return (
    <>
      <ThemeToggleWrapper>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="theme-selector">{t("Change Theme")}</InputLabel>
          <Select
            id="theme-selector"
            value={theme}
            onChange={changeTheme}
            label={t("Change Theme")}
          >
            <MenuItem value={"PureLightTheme"}>Light</MenuItem>
            {/* <MenuItem value={'LightBloomTheme'}>Dark Side</MenuItem>
            <MenuItem value={'GreyGooseTheme'}>Grey</MenuItem> */}
            {/* <Divider style={{margin : 8}} /> */}
            <MenuItem value={"NebulaFighterTheme"}>Semi Dark</MenuItem>
            <MenuItem value={"DarkSpacesTheme"}>Dark</MenuItem>
          </Select>
        </FormControl>
      </ThemeToggleWrapper>
      <Divider />
    </>
  );
};

export default ThemeToggle;
