import { Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { accountSelect } from "src/slices/account-slice";

import { USER_TYPES } from "src/utils/info-base";

const AuthenticatedGuard = ({ children }) => {
  const { userName, token, userType } = useSelector(accountSelect);
  const location = useLocation();

  if (!token || !userName) {
    return <Redirect to="/login" />;
  }
  const ADMIN_PATH = "/admin";
  const OFFICE_PATH = "/manage";
  const AGENTS_PATH = "/agent";
  const SENDER_PATH = "/agent/s";
  const DIST_PATH = "/agent/d";

  if (userType === USER_TYPES.ADMIN) {
    if (location.pathname.startsWith(AGENTS_PATH)) {
      return <Redirect to="/manage/dashboard" />;
    }
  }
  if (userType === USER_TYPES.OFFICE) {
    if (location.pathname.startsWith(ADMIN_PATH)) {
      return <Redirect to="/manage/dashboard" />;
    }
    if (location.pathname.startsWith(AGENTS_PATH)) {
      return <Redirect to="/manage/dashboard" />;
    }
  }

  if (userType === USER_TYPES.AGENT_BOTH) {
    if (location.pathname.startsWith(ADMIN_PATH)) {
      return <Redirect to="/agent/s/dashboard" />;
    }
    if (location.pathname.startsWith(OFFICE_PATH)) {
      return <Redirect to="/agent/s/dashboard" />;
    }
  }

  if (userType === USER_TYPES.AGENT_SENDER) {
    if (location.pathname.startsWith(ADMIN_PATH)) {
      return <Redirect to="/agent/s/dashboard" />;
    }
    if (location.pathname.startsWith(OFFICE_PATH)) {
      return <Redirect to="/agent/s/dashboard" />;
    }
    if (location.pathname.startsWith(DIST_PATH)) {
      return <Redirect to="/agent/s/dashboard" />;
    }
  }

  if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
    if (location.pathname.startsWith(ADMIN_PATH)) {
      return <Redirect to="/agent/d/dashboard" />;
    }
    if (location.pathname.startsWith(OFFICE_PATH)) {
      return <Redirect to="/agent/d/dashboard" />;
    }
    if (location.pathname.startsWith(SENDER_PATH)) {
      return <Redirect to="/agent/d/dashboard" />;
    }
  }

  return <>{children}</>;
};

export default AuthenticatedGuard;
