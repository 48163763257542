import { combineReducers } from '@reduxjs/toolkit';

import accountSlice from './../slices/account-slice';
import officeSlice from './../slices/office-slice';
import senderSlice from 'src/slices/sender-slice';
import commonSlice from 'src/slices/common-slice';
import distSlice from 'src/slices/dist-slice';

const rootReducer = combineReducers({
  accountData: accountSlice,
  officeData: officeSlice,
  senderData: senderSlice,
  commonData: commonSlice,
  distData: distSlice
});

export default rootReducer;
