import { createSlice } from '@reduxjs/toolkit';

const baseStructureArray = {
  isLoading: true,
  data: []
};
const baseStructureObject = {
  isLoading: true,
  data: {}
};
const sendRateInfStrObj = {
  isLoading: true,
  firstTime: true,
  data: {}
};

export const senderSlice = createSlice({
  name: 'sender',
  initialState: {
    senderRateInfo: { ...sendRateInfStrObj },
    senderAccountsInfo: { ...baseStructureArray },
    senderOrdersInfo: { ...baseStructureArray },
    senderDepositsInfo: { ...baseStructureArray },
    senderPayments: { ...baseStructureArray },
    senderPaymentStat: { ...baseStructureArray },
    senderDashboard: {
      isLoading: true,
      data: {
        orders: {
          countAllOrders: '',
          countSentOrders: '',
          countProgressOrders: '',
          countCancelledOrders: ''
        },
        topOrdCo: [],
        deposits: {
          countAllDeposits: '',
          countApprovedDeposits: '',
          countProgressDeposits: '',
          countCancelledDeposits: ''
        }
      }
    }
  },
  reducers: {
    setSenderRateInfo: (state, action) => {
      state.senderRateInfo = {
        data: action.payload,
        isLoading: false,
        firstTime: false
      };
    },
    setSenderRateInfoLoading: (state, action) => {
      state.senderRateInfo.isLoading = action.payload;
    },
    setSenderAccountsInfo: (state, action) => {
      state.senderAccountsInfo = {
        data: action.payload,
        isLoading: false
      };
    },
    setSenderAccountsInfoLoading: (state, action) => {
      state.senderAccountsInfo.isLoading = action.payload;
    },
    setSenderOrdersInfo: (state, action) => {
      state.senderOrdersInfo = {
        data: action.payload,
        isLoading: false
      };
    },
    setSenderOrdersInfoLoading: (state, action) => {
      state.senderOrdersInfo.isLoading = action.payload;
    },
    setSenderDepositsInfo: (state, action) => {
      state.senderDepositsInfo = {
        data: action.payload,
        isLoading: false
      };
    },
    setSenderDepositsInfoLoading: (state, action) => {
      state.senderDepositsInfo.isLoading = action.payload;
    },
    setSenderDashboard: (state, action) => {
      state.senderDashboard = {
        data: action.payload,
        isLoading: false
      };
    },
    setSenderDashboardLoading: (state, action) => {
      state.senderDashboard.isLoading = action.payload;
    },
    setSenderPayments: (state, action) => {
      state.senderPayments = {
        data: action.payload,
        isLoading: false
      };
    },
    setSenderPaymentsLoading: (state, action) => {
      state.senderPayments.isLoading = action.payload;
    },
    setSenderPaymentStat: (state, action) => {
      state.senderPaymentStat = {
        data: action.payload,
        isLoading: false
      };
    },
    setSenderPaymentStatLoading: (state, action) => {
      state.senderPaymentStat.isLoading = action.payload;
    },
    emptyAllSenderSlice: (state) => {
      state.senderRateInfo = { ...sendRateInfStrObj };
      state.senderAccountsInfo = { ...baseStructureArray };
      state.senderOrdersInfo = { ...baseStructureArray };
      state.senderDepositsInfo = { ...baseStructureArray };
      state.senderPayments = { ...baseStructureArray };
      state.senderPaymentStat = { ...baseStructureArray };
      state.senderDashboard = {
        isLoading: true,
        data: {
          orders: {
            countAllOrders: '',
            countSentOrders: '',
            countProgressOrders: '',
            countCancelledOrders: ''
          },
          topOrdCo: [],
          deposits: {
            countAllDeposits: '',
            countApprovedDeposits: '',
            countProgressDeposits: '',
            countCancelledDeposits: ''
          }
        }
      };
    }
  }
});

export const {
  setSenderRateInfo,
  setSenderRateInfoLoading,
  setSenderAccountsInfo,
  setSenderAccountsInfoLoading,
  setSenderOrdersInfo,
  setSenderOrdersInfoLoading,
  setSenderDepositsInfo,
  setSenderDepositsInfoLoading,
  setSenderDashboard,
  setSenderDashboardLoading,
  setSenderPayments,
  setSenderPaymentsLoading,
  setSenderPaymentStat,
  setSenderPaymentStatLoading,
  emptyAllSenderSlice
} = senderSlice.actions;

export const selectSenderRateInfo = (state) => state.senderData.senderRateInfo;

export const selectSenderAccountsInfo = (state) =>
  state.senderData.senderAccountsInfo;

export const selectSenderOrdersInfo = (state) =>
  state.senderData.senderOrdersInfo;

export const selectSenderDepositsInfo = (state) =>
  state.senderData.senderDepositsInfo;

export const selectSenderPayments = (state) => state.senderData.senderPayments;

export const selectSenderPaymentStat = (state) =>
  state.senderData.senderPaymentStat;

export const selectSenderDashboard = (state) =>
  state.senderData.senderDashboard;

export default senderSlice.reducer;
