import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { accountSelect } from 'src/slices/account-slice';
import { USER_TYPES } from 'src/utils/info-base';

const GuestGuard = ({ children }) => {
  const { userName, token, userType } = useSelector(accountSelect);

  if (token && userName) {
    if (userType === USER_TYPES.ADMIN || userType === USER_TYPES.OFFICE) {
      return <Redirect to="/manage/dashboard" />;
    }
    if (
      userType === USER_TYPES.AGENT_SENDER ||
      userType === USER_TYPES.AGENT_BOTH
    ) {
      return <Redirect to="/agent/s/dashboard" />;
    }
    if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
      return <Redirect to="/agent/d/dashboard" />;
    }

    <Redirect to="/logout" />;
  }
  return <>{children}</>;
};

export default GuestGuard;
