import { createSlice } from '@reduxjs/toolkit';
const baseStructureArray = {
  isLoading: true,
  data: []
};
const baseStructureObject = {
  isLoading: true,
  data: {}
};

export const distSlice = createSlice({
  name: 'dist',
  initialState: {
    distOrdersInfo: { ...baseStructureArray },
    distDepositCurr: { ...baseStructureArray },
    distDepositsInfo: { ...baseStructureArray },
    distPayments: { ...baseStructureArray },
    distPaymentStat: { ...baseStructureArray },
    distBalanceInfo: { ...baseStructureArray },
    distDashboard: {
      isLoading: true,
      data: {
        orders: {
          countAllOrders: '',
          countSentOrders: '',
          countProgressOrders: '',
          countCancelledOrders: ''
        },
        topOrdCo: [],
        deposits: {
          countAllDeposits: '',
          countApprovedDeposits: '',
          countProgressDeposits: '',
          countCancelledDeposits: ''
        }
      }
    }
  },
  reducers: {
    setDistOrdersInfo: (state, action) => {
      // done
      state.distOrdersInfo = {
        data: action.payload,
        isLoading: false
      };
    },
    setDistOrdersInfoLoading: (state, action) => {
      state.distOrdersInfo.isLoading = action.payload;
    },
    setDistDepositCurr: (state, action) => {
      state.distDepositCurr.data = {
        data: action.payload,
        isLoading: false
      };
    },
    setDistDepositCurrLoading: (state, action) => {
      state.distDepositCurr.isLoading = false;
    },
    setDistDepositsInfo: (state, action) => {
      state.distDepositsInfo = {
        data: action.payload,
        isLoading: false
      };
    },
    setDistDepositsInfoLoading: (state, action) => {
      state.distDepositsInfo.isLoading = action.payload;
    },
    setDistDashboard: (state, action) => {
      state.distDashboard = {
        data: action.payload,
        isLoading: false
      };
    },
    setDistDashboardLoading: (state, action) => {
      state.distDashboard.isLoading = action.payload;
    },
    setDistPayments: (state, action) => {
      state.distPayments = {
        data: action.payload,
        isLoading: false
      };
    },
    setDistPaymentsLoading: (state, action) => {
      state.distPayments.isLoading = action.payload;
    },
    setDistPaymentStat: (state, action) => {
      state.distPaymentStat = {
        data: action.payload,
        isLoading: false
      };
    },
    setDistPaymentStatLoading: (state, action) => {
      state.distPaymentStat.isLoading = action.payload;
    },
    setDistBalanceInfo: (state, action) => {
      state.distBalanceInfo = {
        data: action.payload,
        isLoading: false
      };
    },
    setDistBalanceInfoLoading: (state, action) => {
      state.distBalanceInfo.isLoading = action.payload;
    },
    emptyAllDistSlice: (state) => {
      state.distOrdersInfo = { ...baseStructureArray };
      state.distDepositCurr = { ...baseStructureArray };
      state.distDepositsInfo = { ...baseStructureArray };
      state.distPayments = { ...baseStructureArray };
      state.distPaymentStat = { ...baseStructureArray };
      state.distBalanceInfo = { ...baseStructureArray };
      state.distDashboard = {
        isLoading: true,
        data: {
          orders: {
            countAllOrders: '',
            countSentOrders: '',
            countProgressOrders: '',
            countCancelledOrders: ''
          },
          topOrdCo: [],
          deposits: {
            countAllDeposits: '',
            countApprovedDeposits: '',
            countProgressDeposits: '',
            countCancelledDeposits: ''
          }
        }
      };
    }
  }
});

export const {
  setDistOrdersInfo,
  setDistOrdersInfoLoading,
  setDistDepositCurr,
  setDistDepositCurrLoading,
  setDistDepositsInfo,
  setDistDepositsInfoLoading,
  setDistDashboard,
  setDistDashboardLoading,
  setDistPayments,
  setDistPaymentsLoading,
  setDistPaymentStat,
  setDistPaymentStatLoading,
  setDistBalanceInfo,
  setDistBalanceInfoLoading,
  emptyAllDistSlice
} = distSlice.actions;

export const selectDistOrdersInfo = (state) => state.distData.distOrdersInfo;

export const selectDistDepositCurr = (state) =>
  state.distData.setDistDepositCurr;

export const selectDistDepositsInfo = (state) =>
  state.distData.distDepositsInfo;

export const selectDistPayments = (state) => state.distData.distPayments;

export const selectDistPaymentStat = (state) => state.distData.distPaymentStat;

export const selectDistBalanceInfo = (state) => state.distData.distBalanceInfo;

export const selectDistDashboard = (state) => state.distData.distDashboard;

export default distSlice.reducer;
