import { useContext } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { SidebarContext } from "src/contexts/SidebarContext";
 
import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";

import {
  Box, 
  IconButton,
  Divider,
  Drawer, 
  Typography,
} from "@material-ui/core";
 
import styled from "styled-components";
import SidebarMenu from "./SidebarMenu";
import { CURRENT_VERSION } from "src/utils/info-base";

const SidebarWrapper = styled(Box)`
  width: 280px;
  color: #6e759f;
  background: inherit;
  box-shadow: 2px 0 3px rgba(159, 162, 191, 0.18),
    1px 0 1px rgba(159, 162, 191, 0.32);
  height: 100%;
  @media (min-width: 1280px) {
    position: fixed;
    z-index: 10;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

const TopSection = styled(Box)`
  margin: 8px;
  display : flex;
  justify-content : flex-end;
`;

const SidebarDivider = styled(Divider)`
  background: #f6f8fb;
`;

function Sidebar() {
 
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();

  return (
    <>
      {/* <Hidden lgDown>
        <SidebarWrapper>
          <Scrollbars autoHide>
            <SidebarDivider sx={{ my: 2, mx: 2 }} />
            <SidebarMenu />
          </Scrollbars>
        </SidebarWrapper>
      </Hidden> */}
      {/* <Hidden lgUp> */}
      <Drawer
        anchor="left"
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          <Scrollbars autoHide>
            <TopSection>
              <IconButton color="secondary" onClick={closeSidebar}>
                <CloseTwoToneIcon />
              </IconButton>
            </TopSection>
            <SidebarDivider
              style={{ 
                marginBottom: 16,
               
              }}
            />
            <SidebarMenu />
            <Box style={{ margin: 8, paddingLeft : 16, }}>
              <Typography variant="caption">{CURRENT_VERSION}</Typography> 
            </Box>
          </Scrollbars>
        </SidebarWrapper>
      </Drawer>
      {/* </Hidden> */}
    </>
  );
}

export default Sidebar;
