import currencyJS from "currency.js";

import {
  STATUS_TYPES
} from "./info-base";

import {format} from 'date-fns';

export const helperFunctions = {
  capitalizeFirstLetter: (string) => {
    return string.toLowerCase().charAt(0).toUpperCase() + string.slice(1);
  },
};

export const alpha = (numG) => {
  return `rgba(255, 255, 255, ${numG})`;
};

const checkAmountAndReturnRate = (amt, amtArray) => {
  if (!amtArray.length) {
    return 0;
  }
  let theAmount = currencyJS(amt).value;

  let theRate;

  for (let i = 0; i < amtArray.length; i++) {
    if (
      Number(amtArray[i].startAmount) <= Number(theAmount) &&
      Number(amtArray[i].endAmount) === 0
    ) {
      theRate = amtArray[i].rate;
      break;
    } else if (
      Number(amtArray[i].startAmount) <= Number(theAmount) &&
      Number(amtArray[i].endAmount) >= Number(theAmount)
    ) {
      theRate = amtArray[i].rate;
      break;
    }
  }

  return theRate;
};

const checkFromAnyCountry = (rateInfo, fromCurrency, toCurrency, amount) => {
  if (!rateInfo[fromCurrency]["Any"]) {
    return 0;
  }
  if (!rateInfo[fromCurrency]["Any"]["Any"]) {
    return 0;
  }
  if (!rateInfo[fromCurrency]["Any"]["Any"][toCurrency]) {
    return 0;
  }

  return checkAmountAndReturnRate(
    amount,
    rateInfo[fromCurrency]["Any"]["Any"][toCurrency]
  );
};
const checkFromCountryAnyCity = (
  rateInfo,
  fromCurrency,
  toCountry,
  toCurrency,
  amount
) => {
  if (!rateInfo[fromCurrency][toCountry]["Any"]) {
    return checkFromAnyCountry(rateInfo, fromCurrency, toCurrency, amount);
  }
  if (!rateInfo[fromCurrency][toCountry]["Any"][toCurrency]) {
    return checkFromAnyCountry(rateInfo, fromCurrency, toCurrency, amount);
  }

  return checkAmountAndReturnRate(
    amount,
    rateInfo[fromCurrency][toCountry]["Any"][toCurrency]
  );
};

export const calculateSendingRate = (
  rateInfo,
  amount,
  fromCurrency,
  toCountry,
  toCity,
  toCurrency
) => {
  if (!rateInfo[fromCurrency]) {
    return 0;
  }

  if (!rateInfo[fromCurrency][toCountry]) {
    return checkFromAnyCountry(rateInfo, fromCurrency, toCurrency, amount);
  }

  if (!rateInfo[fromCurrency][toCountry][toCity]) {
    return checkFromCountryAnyCity(
      rateInfo,
      fromCurrency,
      toCountry,
      toCurrency,
      amount
    );
  }

  if (!rateInfo[fromCurrency][toCountry][toCity][toCurrency]) {
    return checkFromCountryAnyCity(
      rateInfo,
      fromCurrency,
      toCountry,
      toCurrency,
      amount
    );
  }

  return checkAmountAndReturnRate(
    amount,
    rateInfo[fromCurrency][toCountry][toCity][toCurrency]
  );
};

export const getSenderOrderStats = (senderDataFilt) => {
  let repObj = {};
  let baseObj = {
    requestedSUM: 0,
    requestedCounter: 0,
    approvedSUM: 0,
    approvedCounter: 0,
    sentSUM: 0,
    sentCounter: 0,
    returnedSUM: 0,
    returnedCounter: 0,
    cancelledSUM: 0,
    cancelledCounter: 0,
    totalSUM: 0,
    totalCounter: 0,
  };
  senderDataFilt.forEach((item) => {
    let thisCurr = item.from_currency;
    if (!repObj[thisCurr]) {
      repObj[thisCurr] = {
        ...baseObj
      };
    }
    repObj[thisCurr]["currency"] = thisCurr;

    repObj[thisCurr][`${item.status}Counter`] =
      Number(repObj[thisCurr][`${item.status}Counter`]) + 1;

    repObj[thisCurr][`${item.status}SUM`] = currencyJS(
      repObj[thisCurr][`${item.status}SUM`]
    ).add(item.amount).value;

    if (!(item.status === STATUS_TYPES.order.CANCELLED)) {
      repObj[thisCurr]["totalCounter"] =
        Number(repObj[thisCurr]["totalCounter"]) + 1;

      repObj[thisCurr]["totalSUM"] = currencyJS(
        repObj[thisCurr]["totalSUM"]
      ).add(item.amount).value;
    }
  });

  let repArray = Object.keys(repObj).map((item) => ({
    currency: item,
    ...repObj[item],
  }));

  return repArray;
};

export const getDistOrderStats = (distDataFilt) => {
  let repObj = {};
  let baseObj = {
    requestedSUM: 0,
    requestedCounter: 0,
    approvedSUM: 0,
    approvedCounter: 0,
    sentSUM: 0,
    sentCounter: 0,
    returnedSUM: 0,
    returnedCounter: 0,
    cancelledSUM: 0,
    cancelledCounter: 0,
    totalSUM: 0,
    totalCounter: 0,
  };
  distDataFilt.forEach((item) => {
    let thisCurr = item.to_currency;
    if (!repObj[thisCurr]) {
      repObj[thisCurr] = {
        ...baseObj
      };
    }
    repObj[thisCurr]["currency"] = thisCurr;

    repObj[thisCurr][`${item.status}Counter`] =
      Number(repObj[thisCurr][`${item.status}Counter`]) + 1;

    repObj[thisCurr][`${item.status}SUM`] = currencyJS(
      repObj[thisCurr][`${item.status}SUM`]
    ).add(item.total_amount).value;

    if (!(item.status === STATUS_TYPES.order.CANCELLED)) {
      repObj[thisCurr]["totalCounter"] =
        Number(repObj[thisCurr]["totalCounter"]) + 1;

      repObj[thisCurr]["totalSUM"] = currencyJS(
        repObj[thisCurr]["totalSUM"]
      ).add(item.total_amount).value;
    }
  });

  let repArray = Object.keys(repObj).map((item) => ({
    currency: item,
    ...repObj[item],
  }));

  return repArray;
};

export const getAgentDepositStats = (theDataFilt) => {
  let repObj = {};
  let baseObj = {
    requestedSUM: 0,
    requestedCounter: 0,
    approvedSUM: 0,
    approvedCounter: 0,
    rejectedSUM: 0,
    rejectedCounter: 0,
    totalSUM: 0,
    totalCounter: 0,
  };

  theDataFilt.forEach((item) => {
    let thisCurr = item.currency;
    if (!repObj[thisCurr]) {
      repObj[thisCurr] = {
        ...baseObj
      };
    }
    repObj[thisCurr]["currency"] = thisCurr;

    repObj[thisCurr][`${item.status}Counter`] =
      Number(repObj[thisCurr][`${item.status}Counter`]) + 1;

    repObj[thisCurr][`${item.status}SUM`] = currencyJS(
      repObj[thisCurr][`${item.status}SUM`]
    ).add(item.amount).value;

    // if status is rejected don't sum up
    if (!(item.status === STATUS_TYPES.deposit.REJECTED)) {
      repObj[thisCurr]["totalSUM"] = currencyJS(
        repObj[thisCurr]["totalSUM"]
      ).add(item.amount).value;
      repObj[thisCurr]["totalCounter"] =
        Number(repObj[thisCurr]["totalCounter"]) + 1;
    }
  });

  let repArray = Object.keys(repObj).map((item) => ({
    currency: item,
    ...repObj[item],
  }));

  return repArray;
};

export const getAgentPaymentHistoryStats = (theDataFilt) => {
  let repObj = {};
  let baseObj = {
    paidSUM: 0,
    paidCounter: 0,
    cancelledSUM: 0,
    cancelledCounter: 0,
    totalSUM: 0,
    totalCounter: 0,
  };

  theDataFilt.forEach((item) => {
    let thisCurr = item.currency;
    if (!repObj[thisCurr]) {
      repObj[thisCurr] = {
        ...baseObj
      };
    }
    repObj[thisCurr]["currency"] = thisCurr;

    repObj[thisCurr][`${item.status}Counter`] =
      Number(repObj[thisCurr][`${item.status}Counter`]) + 1;

    repObj[thisCurr][`${item.status}SUM`] = currencyJS(
      repObj[thisCurr][`${item.status}SUM`]
    ).add(item.amount).value;

    // if status is cancelled don't sum up
    // if (!(item.status === STATUS_TYPES.payment.CANCELLED)) {
    repObj[thisCurr]["totalSUM"] = currencyJS(
      repObj[thisCurr]["totalSUM"]
    ).add(item.amount).value;
    repObj[thisCurr]["totalCounter"] =
      Number(repObj[thisCurr]["totalCounter"]) + 1;
    // }
  });

  let repArray = Object.keys(repObj).map((item) => ({
    currency: item,
    ...repObj[item],
  }));

  return repArray;
};

 export const getUserFullName = (usersList,userId) => {
     // TODO use memoized function

     if(!usersList.length) {
        return "";
     }
      
     let userFound = usersList.find(ul => ul.userId === userId);
     if(userFound) {
         return userFound.fullName;
     }
     return "-";
 };
 export const getUserInfo = (usersList,userId) => {
     // TODO use memoized function

     if(!usersList.length) {
        return null;
     }
      
     let userFound = usersList.find(ul => ul.userId === userId);
     if(userFound) {
         return userFound;
     }
     return null;
 };

export const formatMonthAndYear = (givenDate) => { 
  if (!givenDate) return "";   
 return format(new Date(givenDate), "MMMM yyyy");
};
export const getTimeFromGiven = (givenDate) => {
  if (!givenDate) {
    return "";
  }

  return format(new Date(givenDate), "HH:mm:ss").toString();
}
export const  getFullDateMonth = (givenDate) => {
  // This will return in the format of Jan 31 2021
  if (givenDate && givenDate !== "0000-00-00") {
    return format(new Date(givenDate), "MMM dd, yyyy").toString();
  }
  return "";
};

export const chkDbValue = (rowData,field) => rowData[field] === "0" || rowData[field] === 0 || rowData[field] === "0000-00-00" ? "-" : rowData[field]

export const shallowCopy = (arrList) => arrList.map((ord) => ({ ...ord}));