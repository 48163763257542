import { createSlice } from '@reduxjs/toolkit';
import { STATUS_TYPES } from 'src/utils/info-base';
const baseStructureArray = {
  isLoading: true,
  data: []
};
const baseStructureObject = {
  isLoading: true,
  data: {}
};
const srStrObj = {
  isLoading: true,
  data: {
    srHeader: '',
    srFooter: ''
  }
};

const usrSettingsObj = {
  nfxSound: 'on',
  msgSound: 'on',
  chatSound: 'on',
  idleTimeOut: 1000 * 60 * 20
};
const nfxArrStr = {
  isLoading: true,
  unseen: '',
  ringed: '',
  cleared: '',
  data: []
};

const msgArrStr = {
  isLoading: false,
  unseen: 0,
  ringed: '',
  cleared: '',
  data: []
};

const chatStructure = {
  isLoading: false,
  isRegistered: false,
  currentUser: '',
  activeUsers: [],
  unseen: 0,
  ringed: '',
  cleared: '',
  lastInChat: null,
  data: []
};

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    activeCountries: { ...baseStructureArray },
    simpleRate: { ...baseStructureArray },
    workingCurrencies: { ...baseStructureArray },
    beneficiaryCountries: { ...baseStructureArray },
    benCoAddCurr: { ...baseStructureArray },
    notifications: { ...nfxArrStr },
    messages: { ...msgArrStr },
    chat: { ...chatStructure },
    userSettings: { ...usrSettingsObj },
    kvPair: { ...srStrObj }
  },
  reducers: {
    setActiveCountries: (state, action) => {
      state.activeCountries = {
        data: action.payload,
        isLoading: false
      };
    },
    setActiveCountriesLoading: (state, action) => {
      state.activeCountries.isLoading = action.payload;
    },
    setSimpleRate: (state, action) => {
      state.simpleRate = {
        data: action.payload,
        isLoading: false
      };
    },
    setSimpleRateLoading: (state, action) => {
      state.simpleRate.isLoading = action.payload;
    },
    setWorkingCurrencies: (state, action) => {
      state.workingCurrencies = {
        data: action.payload,
        isLoading: false
      };
    },
    setWorkingCurrenciesLoading: (state, action) => {
      state.workingCurrencies.isLoading = action.payload;
    },
    setBeneficiaryCountries: (state, action) => {
      state.beneficiaryCountries = {
        data: action.payload,
        isLoading: false
      };
    },
    setBeneficiaryCountriesLoading: (state, action) => {
      state.beneficiaryCountries.isLoading = action.payload;
    },
    setBenCoAddCurr: (state, action) => {
      state.benCoAddCurr = {
        data: action.payload,
        isLoading: false
      };
    },
    setBenCoAddCurrLoading: (state, action) => {
      state.benCoAddCurr.isLoading = action.payload;
    },
    setNotifications: (state, action) => {
      let unseenNfx = null;
      if (action.payload.length) {
        unseenNfx = action.payload.reduce((acc, item) => {
          if (item.status !== STATUS_TYPES.notification.SEEN) {
            return acc + 1;
          } else {
            return acc;
          }
        }, 0);
      }

      state.notifications = {
        data: action.payload,
        unseen: unseenNfx,
        cleared: false,
        isLoading: false
      };
    },
    setNotificationsLoading: (state, action) => {
      state.notifications.isLoading = action.payload;
    },
    setNotificationsUnseen: (state, action) => {
      state.notifications.unseen = action.payload;
    },
    setNotificationsRinged: (state, action) => {
      state.notifications.ringed = action.payload;
    },
    setNotificationsCleared: (state, action) => {
      state.notifications.cleared = action.payload;
    },
    setMessages: (state, action) => {
      let unseenMsg = 0;
      if (action.payload.length) {
        unseenMsg = action.payload.reduce((acc, item) => {
          if (item.status === STATUS_TYPES.chat.UNSEEN) {
            return acc + 1;
          } else {
            return acc;
          }
        }, 0);
      }

      let unToUpd = Number(state.messages.unseen) + Number(unseenMsg);
      state.messages = {
        data: action.payload,
        unseen: unToUpd,
        isLoading: false,
        ringed: true
      };
    },
    setMessagesLoading: (state, action) => {
      state.messages.isLoading = action.payload;
    },
    setMessagesUnseen: (state, action) => {
      let unseenMsg = state.messages.unseen;
      if (state.messages.data.length) {
        unseenMsg = state.messages.data.reduce((acc, item) => {
          if (item.fromUser !== action.payload) {
            return acc + 1;
          } else {
            return acc;
          }
        }, 0);

        state.messages.unseen = unseenMsg;
      } else {
        state.messages.unseen = unseenMsg;
      }
    },
    setMessagesUnseenDirectly: (state, action) => {
      state.messages.unseen = action.payload;
    },
    incrementMessagesUnseen: (state, action) => {
      state.messages.unseen = Number(state.messages.unseen) + 1;
    },
    setMessagesRinged: (state, action) => {
      state.messages.ringed = action.payload;
    },
    setMessagesCleared: (state, action) => {
      state.messages.cleared = action.payload;
    },
    setChat: (state, action) => {
      state.chat = {
        data: action.payload,
        isLoading: false
      };
    },
    setChatLoading: (state, action) => {
      state.chat.isLoading = action.payload;
    },
    setChatActiveUsers: (state, action) => {
      state.chat.activeUsers = action.payload;
    },
    setChatCurrentUser: (state, action) => {
      state.chat.currentUser = action.payload;
    },
    setChatIsRegistered: (state, action) => {
      state.chat.isRegistered = action.payload;
    },
    pushMsgToCurrentChat: (state, action) => {
      state.chat.data.push(action.payload);
    },
    setLastIncomingChat: (state, action) => {
      state.chat.lastInChat = action.payload;
    },
    setLastIncomingChatStatus: (state, action) => {
      if (
        state.chat.lastInChat &&
        state.chat.lastInChat.fromUser === action.payload.currentUser
      ) {
        state.chat.lastInChat.status = action.payload.status;
      }
    },
    emptyChats: (state, action) => {
      state.chat.data = [];
    },
    setUserSettings: (state, action) => {
      state.userSettings = action.payload;
    },
    setKvPair: (state, action) => {
      state.kvPair.data[action.payload.kvKey] = action.payload.kvValue;
      state.kvPair.isLoading = false;
    },
    setKvPairLoading: (state, action) => {
      state.kvPair.isLoading = action.payload;
    },

    emptyAllCommonSlice: (state) => {
      state.activeCountries = { ...baseStructureArray };
      state.workingCurrencies = { ...baseStructureArray };
      state.beneficiaryCountries = { ...baseStructureArray };
      state.benCoAddCurr = { ...baseStructureArray };
      state.notifications = { ...nfxArrStr };
      state.messages = { ...msgArrStr };
      state.chat = { ...chatStructure };
      state.kvPair = { ...srStrObj };
      state.userSettings = { ...usrSettingsObj };
    }
  }
});

export const {
  setActiveCountries,
  setSimpleRate,
  setSimpleRateLoading,
  setActiveCountriesLoading,
  setWorkingCurrencies,
  setWorkingCurrenciesLoading,
  setBeneficiaryCountries,
  setBeneficiaryCountriesLoading,
  setBenCoAddCurr,
  setBenCoAddCurrLoading,
  setNotifications,
  setNotificationsLoading,
  setNotificationsUnseen,
  setNotificationsRinged,
  setNotificationsCleared,
  setMessages,
  setMessagesLoading,
  setMessagesUnseen,
  setMessagesUnseenDirectly,
  incrementMessagesUnseen,
  setMessagesRinged,
  setMessagesCleared,
  setChat,
  setChatLoading,
  setChatActiveUsers,
  setChatCurrentUser,
  setLastIncomingChat,
  setLastIncomingChatStatus,
  emptyChats,
  pushMsgToCurrentChat,
  setChatIsRegistered,
  setUserSettings,
  setKvPair,
  setKvPairLoading,
  emptyAllCommonSlice
} = commonSlice.actions;

export const selectActiveCountries = (state) =>
  state.commonData.activeCountries;

export const selectSimpleRate = (state) => state.commonData.simpleRate;

export const selectWorkingCurrencies = (state) =>
  state.commonData.workingCurrencies;

export const selectBeneficiaryCountries = (state) =>
  state.commonData.beneficiaryCountries;

export const selectBenCoAddCurr = (state) => state.commonData.benCoAddCurr;

export const selectNotifications = (state) => state.commonData.notifications;

export const selectMessages = (state) => state.commonData.messages;

export const selectChat = (state) => state.commonData.chat;

export const selectUserSettings = (state) => state.commonData.userSettings;

export const selectKvPair = (state) => state.commonData.kvPair;

export default commonSlice.reducer;
