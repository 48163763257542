import React, { useState } from "react";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles"; //  CHANGED
import { themeCreator } from "./base";
// import { makeStyles as StylesProvider } from "@material-ui/core/styles"; // CHANGED

export const ThemeContext = React.createContext((themeName) => {});

const ThemeProviderFunc = function (props) {
  const curThemeName = localStorage.getItem("appTheme") || "PureLightTheme";
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = (themeName) => {
    localStorage.setItem("appTheme", themeName);
    _setThemeName(themeName);
  };

  return (
    <ThemeContext.Provider value={setThemeName}>
      <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProviderFunc;
/* <StylesProvider injectFirst>
 <CacheProvider value={cacheRtl}>
<ThemeContext.Provider value={setThemeName}>
  <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
</ThemeContext.Provider>
 </CacheProvider> 
</StylesProvider> 
*/
