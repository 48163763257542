import axios from "axios";
import store from "src/store";
import { RESPONSE_TYPES, MISC_SETTINGS } from "src/utils/info-base";

import { setLogout } from "src/slices/account-slice";
import { closeAccount } from "./action-center";

import devLog from "./dev-log";

const axiosIns = axios.create({
  baseURL: MISC_SETTINGS.getBaseDomain(),
  timeout: 30000,
});
const LogOutFunc = () => {
  try {
    axios.post(
      MISC_SETTINGS.getBaseDomain() + "/logout",
      {},
      {
        headers: {
          authorization: `Bearer ${store.getState().accountData.token}`,
        },
      }
    );
  } catch (error) {
    devLog("error logout", error);
  }
  store.dispatch(setLogout());
};
axiosIns.interceptors.request.use((request) => {
  devLog("Starting Request", request.url);
  devLog(request.data);
  return request;
});

axiosIns.interceptors.response.use((response) => {
  if (
    (response.data.message &&
      response.data.message === RESPONSE_TYPES.actions.AUTH_SESSION_EXPIRED) ||
    response.data.message === RESPONSE_TYPES.actions.AUTH_WRONG ||
    response.data.message === RESPONSE_TYPES.actions.AUTH_ERROR
  ) {
    devLog("___________---------___________");
    devLog("setting logout, session expired");
    LogOutFunc();
  } else if (
    response.data.message &&
    response.data.message === RESPONSE_TYPES.actions.AUTH_CLOSE
  ) {
    closeAccount();
  } else {
    return response;
  }
});

axiosIns.interceptors.response.use((response) => {
  if (response && response.data) {
    devLog("Response:", response.data);
    devLog("_________AXIOS-END________");
    return response;
  }
});

export default axiosIns;
