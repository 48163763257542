import { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import SidebarLayout from "src/layouts/SidebarLayout";

import BaseLayout from "src/layouts/BaseLayout";

import SuspenseLoader from "src/components/SuspenseLoader";
import GuestGuard from "src/contexts/Guards/guest-guard";
import AuthenticatedGuard from "src/contexts/Guards/authenticated-guard";

export const renderRoutes = (routes) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Switch>
      {" "}
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {" "}
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}{" "}
                </Layout>{" "}
              </Guard>
            )}
          />
        );
      })}{" "}
    </Switch>{" "}
  </Suspense>
);

const routes = [
  // END USERS - AGENTS
  {
    path: "/agent",
    guard: AuthenticatedGuard, //
    layout: SidebarLayout,
    routes: [
      // SENDER'S ROUTES
      {
        exact: true,
        path: "/agent/s/dashboard",
        component: lazy(() =>
          import("./pages/agent-sender/dashboard/agent-sender-dashboard")
        ),
      },
      {
        exact: true,
        path: "/agent/s/orders/new",
        component: lazy(() =>
          import("./pages/agent-sender/orders/new-order/sender-new-order")
        ),
      },
      {
        exact: true,
        path: "/agent/s/orders",
        component: lazy(() =>
          import("./pages/agent-sender/orders/view-orders/view-sender-orders")
        ),
      },
      {
        exact: true,
        path: "/agent/s/orders/view/:orderId",
        component: lazy(() =>
          import(
            "./pages/agent-sender/orders/view-single/view-sender-single-order"
          )
        ),
      },
      {
        exact: true,
        path: "/agent/s/deposits/new",
        component: lazy(() =>
          import("./pages/agent-sender/deposits/new-deposit/sender-new-deposit")
        ),
      },
      {
        exact: true,
        path: "/agent/s/deposits/view",
        component: lazy(() =>
          import(
            "./pages/agent-sender/deposits/view-deposits/sender-view-deposits"
          )
        ),
      },
      {
        exact: true,
        path: "/agent/s/payments",
        component: lazy(() =>
          import("./pages/agent-sender/payments/sender-view-payments")
        ),
      },
      // DIST. ROUTES
      {
        exact: true,
        path: "/agent/d/dashboard",
        component: lazy(() =>
          import("./pages/agent-dist/dashboard/dist-dashboard")
        ),
      },
      {
        exact: true,
        path: "/agent/d/orders/active",
        component: lazy(() =>
          import("./pages/agent-dist/orders/active-orders/active-orders")
        ),
      },
      {
        exact: true,
        path: "/agent/d/orders",
        component: lazy(() =>
          import("./pages/agent-dist/orders/view-orders/dist-view-orders")
        ),
      },
      {
        exact: true,
        path: "/agent/d/deposits/new",
        component: lazy(() =>
          import("./pages/agent-dist/deposits/new-deposit/dist-new-deposit")
        ),
      },
      {
        exact: true,
        path: "/agent/d/deposits",
        component: lazy(() =>
          import("./pages/agent-dist/deposits/view-deposits/dist-view-deposits")
        ),
      },
      {
        exact: true,
        path: "/agent/d/payments",
        component: lazy(() =>
          import("./pages/agent-dist/payments/dist-view-payments")
        ),
      },
      {
        component: () => <Redirect to="/status/404" />,
      },
    ],
  },
  // MANAGE
  {
    path: "/manage",
    guard: AuthenticatedGuard,
    layout: SidebarLayout,
    routes: [
      // dashboard
      {
        exact: true,
        path: "/manage/dashboard",
        component: lazy(() => import("src/pages/manage/dashboard/dashboard")),
      },
      // users
      {
        exact: true,
        path: "/manage/users/new",
        component: lazy(() =>
          import("src/pages/manage/users/new-user/new-user")
        ),
      },
      {
        exact: true,
        path: "/manage/users",
        component: lazy(() =>
          import("src/pages/manage/users/view-users/view-users")
        ),
      },
      {
        exact: true,
        path: "/manage/users/view/:userId",
        component: lazy(() =>
          import("src/pages/manage/users/single-user/single-user")
        ),
      },
      {
        exact: true,
        path: "/manage/users/edit-user/:userId",
        component: lazy(() =>
          import("src/pages/manage/users/single-user/user-tab/edit-user")
        ),
      },
      // approvals
      {
        exact: true,
        path: "/manage/approvals/order",
        component: lazy(() =>
          import("src/pages/manage/approvals/order-approvals/order-approval")
        ),
      },
      {
        exact: true,
        path: "/manage/approvals/reassign",
        component: lazy(() =>
          import(
            "src/pages/manage/approvals/order-approvals/reassign-order/reassign-order"
          )
        ),
      },
      {
        exact: true,
        path: "/manage/approvals/deposit",
        component: lazy(() =>
          import(
            "src/pages/manage/approvals/deposit-approvals/deposit-approval"
          )
        ),
      },
      // deposits
      {
        exact: true,
        path: "/manage/deposits",
        component: lazy(() =>
          import("src/pages/manage/deposits/manage-deposits")
        ),
      },
      {
        exact: true,
        path: "/manage/deposits/new",
        component: lazy(() =>
          import("src/pages/manage/deposits/new-deposit/new-deposit")
        ),
      },
      // orders
      {
        exact: true,
        path: "/manage/orders",
        // component: lazy(() => import("src/pages/manage/orders/manage-orders")),
        component: lazy(() => import("src/pages/manage/orders/all-orders-report")),
      },
      {
        exact: true,
        path: "/manage/orders/active",
        component: lazy(() =>
          import("src/pages/manage/orders/active-orders/active-orders")
        ),
      },
      {
        exact: true,
        path: "/manage/orders/new",
        component: lazy(() =>
          import("src/pages/manage/orders/new-order/new-order")
        ),
      },
      // rate
      {
        exact: true,
        path: "/manage/simple-rate",
        component: lazy(() =>
          import("src/pages/manage/settings/rates/simple-rates")
        ),
      },
      // salary
      {
        exact: true,
        path: "/manage/salary-groups",
        component: lazy(() =>
          import("src/pages/manage/settings/salary-groups/salary-groups")
        ),
      },
      // message
      {
        exact: true,
        path: "/manage/message/new",
        component: lazy(() => import("src/pages/manage/message/send-message")),
      },
      {
        exact: true,
        path: "/manage/messages",
        component: lazy(() => import("src/pages/manage/message/all-messages")),
      },
      {
        exact: true,
        path: "/manage/balance-info",
        component: lazy(() =>
          import("src/pages/manage/balance-info/all-balance-info")
        ),
      },

      {
        component: () => <Redirect to="/status/404" />,
      },
    ],
  },
  // ADMIN
  {
    path: "/admin",
    guard: AuthenticatedGuard,
    layout: SidebarLayout,
    routes: [
      // dashboard
      {
        exact: true,
        path: "/admin/dashboard",
        component: () => <Redirect to="/manage/dashboard" />,
      },
      // settings
      // deprecated
      // {
      //   exact: true,
      //   path: "/admin/settings/profit-percent",
      //   component: lazy(() =>
      //     import("src/pages/manage/settings/profit-percent/send-profit-percent")
      //   ),
      // },
      {
        exact: true,
        path: "/admin/settings/rules",
        component: lazy(() => import("src/pages/manage/settings/rules/rules")),
      },
      // {
      //   exact: true,
      //   path: '/admin/settings/rates',
      //   component: lazy(() => import('src/pages/manage/settings/rates/rates'))
      // },
      {
        exact: true,
        path: "/admin/settings/beneficiary-countries",
        component: lazy(() =>
          import("src/pages/manage/settings/countries/beneficiary-countries")
        ),
      },
      {
        exact: true,
        path: "/admin/settings/countries",
        component: lazy(() =>
          import("src/pages/manage/settings/countries/countries")
        ),
      },
      {
        exact: true,
        path: "/admin/settings/cities",
        component: lazy(() =>
          import("src/pages/manage/settings/cities/cities")
        ),
      },
      // deposits
      {
        exact: true,
        path: "/admin/deposits",
        component: lazy(() =>
          import("src/pages/manage/deposits/manage-deposits")
        ),
      },
      {
        exact: true,
        path: "/admin/deposits/new",
        component: lazy(() =>
          import("src/pages/manage/deposits/new-deposit/new-deposit")
        ),
      },
      // orders
      {
        exact: true,
        path: "/admin/orders",
        component: lazy(() => import("src/pages/manage/orders/manage-orders")),
      },
      {
        exact: true,
        path: "/admin/orders/new",
        component: lazy(() =>
          import("src/pages/manage/orders/new-order/new-order")
        ),
      },
      // payments
      {
        exact: true,
        path: "/admin/payments/agents",
        component: lazy(() =>
          import(
            "src/pages/manage/payments/agents-payment-stat/agents-payment-stat"
          )
        ),
      },
      {
        exact: true,
        path: "/admin/payments",
        component: lazy(() =>
          import("src/pages/manage/payments/manage-payments")
        ),
      },
      {
        exact: true,
        path: "/admin/payments/new",
        component: lazy(() =>
          import("src/pages/manage/payments/new-payment/new-payment")
        ),
      },
      // report
      {
        exact: true,
        path: "/admin/report/auto",
        component: lazy(() =>
          import("src/pages/manage/reports/auto/auto-report")
        ),
      },
      {
        exact: true,
        path: "/admin/report/auto/orders",
        component: lazy(() =>
          import("src/pages/manage/reports/auto/tabular-reports/orders-report")
        ),
      },
      {
        exact: true,
        path: "/admin/report/auto/deposits",
        component: lazy(() =>
          import(
            "src/pages/manage/reports/auto/tabular-reports/deposits-report"
          )
        ),
      },
      {
        exact: true,
        path: "/admin/report/auto/payments",
        component: lazy(() =>
          import(
            "src/pages/manage/reports/auto/tabular-reports/payments-report"
          )
        ),
      },
      {
        exact: true,
        path: "/admin/report/auto/countries",
        component: lazy(() =>
          import(
            "src/pages/manage/reports/auto/country-reports/country-list-report"
          )
        ),
      },
      {
        exact: true,
        path: "/admin/report/auto/date",
        component: lazy(() =>
          import("src/pages/manage/reports/auto/tabular-reports/by-date-report")
        ),
      },
      {
        component: () => <Redirect to="/status/404" />,
      },
    ],
  },

  // common
  {
    exact: true,
    guard: AuthenticatedGuard,
    layout: SidebarLayout,
    path: "/change-password",
    component: lazy(() =>
      import("src/pages/common/change-password/change-password")
    ),
  },
  {
    exact: true,
    guard: AuthenticatedGuard,
    layout: SidebarLayout,
    path: "/help",
    component: lazy(() => import("src/pages/common/help/help-page")),
  },
  {
    exact: true,
    guard: AuthenticatedGuard,
    layout: SidebarLayout,
    path: "/close",
    component: lazy(() => import("src/pages/auth/close/close-account")),
  },
  {
    exact: true,
    guard: AuthenticatedGuard,
    layout: SidebarLayout,
    path: "/notifications",
    component: lazy(() =>
      import("src/pages/common/notifications/notifications-page")
    ),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("src/pages/auth/login/login-page")),
  },
  {
    exact: true,
    guard: AuthenticatedGuard,
    path: "/logout",
    component: lazy(() => import("src/pages/auth/logout/logout-page")),
  },

  {
    exact: true,
    path: "/status/404",
    component: lazy(() => import("src/pages/status/status-404/status-404")),
  },
  // {
  //   exact: true,
  //   path: "/status/500",
  //   component: lazy(() => import("src/pages/status/status-500/status-500")),
  // },
  // {
  //   exact: true,
  //   path: "/status/coming-soon",
  //   component: lazy(() => import("src/pages/status/coming-soon/coming-soon")),
  // },
  // {
  //   exact: true,
  //   path: "/status/maintenance",
  //   component: lazy(() => import("src/pages/status/maintenance/maintenance")),
  // },
  {
    path: "*",
    layout: BaseLayout,
    routes: [
      {
        exact: true,
        path: "/",
        component: () => <Redirect to="/login" />,
      },
      {
        component: () => <Redirect to="/status/404" />,
      },
    ],
  },
];

export default routes;
