export const RESPONSE_TYPES = {
  actions: {
    SUCCESS: "ACTION_SUCCESS",
    ERROR: "ACTION_ERROR",
    ERROR_WITH_PAYLOAD: "ACTION_ERROR_WITH_PAYLOAD",
    WRONG: "ACTION_WRONG",
    NO_DATA: "ACTION_NO_DATA",
    MAX_LIMIT: "ACTION_MAX_LIMIT",
    MIN_LIMIT: "ACTION_MIN_LIMIT",
    NOT_FOUND: "ACTION_NOT_FOUND",
    NOT_FINISHED: "ACTION_NOT_FINISHED",
    EXPIRED: "ACTION_EXPIRED",
    DISABLED: "ACTION_DISABLED",
    BLACKLISTED: "ACTION_BLACKLISTED",
    INVALID: "ACTION_INVALID",
    MISMATCH: "ACTION_MISMATCH",
    INCOMPLETE: "ACTION_INCOMPLETE",
    EXISTS: "ACTION_EXISTS",
    MAIL_ERROR: "ACTION_MAIL_ERROR",
    LOCKED: "ACTION_LOCKED",
    RECAPTCHA_FAILED: "ACTION_RECAPTCHA_FAILED",
    AUTH_SESSION_EXPIRED: "ACTION_SESSION_EXPIRED",
    AUTH_ERROR: "ACTION_AUTH_ERROR",
    AUTH_WRONG: "ACTION_AUTH_WRONG",
    AUTH_CLOSE: "ACTION_AUTH_CLOSE",
  },
};

export const STATUS_TYPES = {
  deposit: {
    REQUESTED: "requested",
    APPROVED: "approved",
    REJECTED: "rejected",
  },
  order: {
    REQUESTED: "requested",
    APPROVED: "approved",
    SENT: "sent",
    RETURNED: "returned",
    CANCELLED: "cancelled",
  },
  orderRequest: {
    REQUESTED: "requested",
    APPROVED: "approved",
    REJECTED: "rejected",
    REQUIRED: "required",
    SENT_TO_SENDER: "sent_to_send",
    SENT_TO_DIST: "sent_to_dist",
    DIST_REJECTED: "dist_rejected",
  },
  orderReturnedStatus: {
    ADMIN: "admin",
    SENDER: "sender",
  },
  orderEditMode: {
    RETURNED_EDIT: "returnedEdit",
    EDIT: "edit",
  },
  payment: {
    PAID: "paid",
    CANCELLED: "cancelled",
  },
  notification: {
    SEEN: "seen",
    UNSEEN: "unseen",
  },
  chat: {
    SEEN: "seen",
    UNSEEN: "unseen",
  },
  nfxType: {
    NEW_ORDER: "new-order",
    ORDER_APPROVED: "order-app",
    ORDER_CREATED_OFFICE: "order-created-office",
    ORDER_EDIT_REQ: "order-edit-req",
    ORDER_EDIT_APPROVED: "order-edit-app",
    ORDER_EDIT_REJECTED: "order-edit-rej",
    ORDER_RETURN_REQ: "order-ret-req",
    ORDER_CANCEL_REQ: "order-can-req",
    ORDER_CANCEL_REJECTED: "order-can-rej",
    ORDER_SENT: "order-sent",
    ORDER_RETURNED: "order-returned",
    ORDER_CANCELLED: "order-cancelled",
    NEW_DEPOSIT: "new-deposit",
    NEW_DEPOSIT_OFFICE: "new-deposit-office",
    DEPOSIT_APPROVED: "deposit-app",
    DEPOSIT_REJECTED: "deposit-rej",
    NEW_PAYMENT: "new-payment",
    ACTIVE_COUNTRIES: "active-co",
    BENEFICIARY_COUNTRIES: "ben-co",
    RATE: "rate",
    KV_PAIR: "kv_pair",
    USER_INFO: "user-info",
    ACCOUNT_INFO: "account-info", // new
    TELEGRAM_ACCESS: "telegram-access",
    LOG_OUT: "log-out",
    LOG_OUT_ALL: "log-out-all",
    CLOSED_ACCOUNT_INFO: "closed-account-info",
    CLOSE_ACCOUNT: "close-account",
  },
  kvType: {
    SIMPLE_RATE_HEADER: "srHeader",
    SIMPLE_RATE_FOOTER: "srFooter",
    OFFICE_CANCEL_LIMIT: "officeCancelLimit",
  },
};

export const SALARY_TYPES = {
  FLAT_PER_ORDER: "flat_per_order",
  COMMISSION: "commission",
  MONTHLY: "monthly",
};
export const PAYMENT_TYPES = {
  CASH: "cash",
  ACCOUNT: "account",
  OTHER: "other",
};
export const USER_TYPES = {
  AGENT_SENDER: "send",
  AGENT_DISTRIBUTOR: "dist",
  AGENT_BOTH: "both",
  ADMIN: "admin",
  OFFICE: "office",
};
export const CURRENT_VERSION = "1.4.4";
const theBaseURL = "http://localhost:39222"; // ON localhost
// const theBaseURL = "http://192.168.1.75:39222"; // ON LAN
// const theBaseURL = "/"; // ON PROD

export const MISC_SETTINGS = {
  strongPasswordRegExp:
    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
  weakPasswordRegExp:
    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
  veryWeakPasswordRegExp: /^.*$/,
  phoneRegExp:
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  currJSFormat: {
    fromCents: false,
    precision: 2,
    symbol: "#",
    separator: ",",
    decimal: ".",
    pattern: `#`,
  },
  baseURL: "/",
  getBaseUrl: () => {
    if (process.env && process.env.NODE_ENV === "development") {
      return theBaseURL;
    }
    return "/";
  },
  getBaseDomain: () => {
    // for axios
    if (process.env && process.env.NODE_ENV === "development") {
      return theBaseURL;
    }
    return "";
  },
  audioNfxURL: "/static/audio/nfx.mp3",
  audioMsgURL: "/static/audio/msg.mp3",
  audioChatURL: "/static/audio/chat.mp3",
};
