import { useTranslation } from "react-i18next";
import { formatDistance } from "date-fns";

import { useState } from "react";

import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@material-ui/core";

import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";

import InfoTwoToneIcon from "@material-ui/icons/InfoTwoTone";
import CheckCircleOutlineTwoToneIcon from "@material-ui/icons/CheckCircleOutlineTwoTone";
import WarningTwoToneIcon from "@material-ui/icons/WarningTwoTone";
import ErrorTwoToneIcon from "@material-ui/icons/ErrorTwoTone";

const UsableTheme = () => {
  const themeN = useTheme();
  return themeN;
};

const InfoNotification = ({
  nfxData: { type, title, message, date, timeStamp },
}) => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const AvatarSuccess = styled(Avatar)`
    background-color: ${UsableTheme().palette.success.main};
    color: ${UsableTheme().palette.getContrastText(
      UsableTheme().palette.success.main
    )};
    width: ${UsableTheme().spacing(6)};
    height: ${UsableTheme().spacing(6)};
    box-shadow: ${UsableTheme().palette.secondary.main};
  `;

  const AvatarError = styled(Avatar)`
    background-color: ${UsableTheme().palette.error.main};
    color: ${UsableTheme().palette.getContrastText(
      UsableTheme().palette.error.main
    )};
    width: ${UsableTheme().spacing(6)};
    height: ${UsableTheme().spacing(6)};
    box-shadow: ${UsableTheme().palette.secondary.main};
  `;

  const AvatarWarning = styled(Avatar)`
    background-color: ${UsableTheme().palette.warning.main};
    color: ${UsableTheme().palette.getContrastText(
      UsableTheme().palette.warning.main
    )};
    width: ${UsableTheme().spacing(6)};
    height: ${UsableTheme().spacing(6)};
    box-shadow: ${UsableTheme().palette.secondary.main};
  `;

  const AvatarInfo = styled(Avatar)`
    background-color: ${UsableTheme().palette.info.main};
    color: ${UsableTheme().palette.getContrastText(
      UsableTheme().palette.info.main
    )};
    width: ${UsableTheme().spacing(6)};
    height: ${UsableTheme().spacing(6)};
    box-shadow: ${UsableTheme().palette.secondary.main};
  `;

  return (
    <ListItem sx={{ display: { xs: "block", sm: "flex" } }}>
      <ListItemAvatar sx={{ mb: { xs: 1, sm: 0 } }}>
        {type === "success" ? (
          <AvatarSuccess sx={{ mr: 2 }} variant="rounded">
            <CheckCircleOutlineTwoToneIcon />
          </AvatarSuccess>
        ) : type === "warning" ? (
          <AvatarWarning sx={{ mr: 2 }} variant="rounded">
            <WarningTwoToneIcon />
          </AvatarWarning>
        ) : type === "error" ? (
          <AvatarError sx={{ mr: 2 }} variant="rounded">
            <ErrorTwoToneIcon />
          </AvatarError>
        ) : (
          <AvatarInfo sx={{ mr: 2 }} variant="rounded">
            <InfoTwoToneIcon />
          </AvatarInfo>
        )}
      </ListItemAvatar>
      <Box flex="1">
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ fontWeight: "bold" }}>
            {title ? title : "Info"}
          </Typography>
          {/* // TODO!important change to locale time string */}
          <Typography
            variant="caption"
            style={{ marginLeft: 8, textTransform: "none" }}
          >
            {formatDistance(new Date(timeStamp), new Date(), {
              addSuffix: true,
            })}
          </Typography>
        </Box>
        {/* <Typography component="span" variant="body2" fontWeight="bold">
          54
        </Typography> */}
        <Typography component="span" variant="body2" color="textSecondary">
          {message || "Hello you have a new notification"}
        </Typography>
      </Box>
    </ListItem>
  );
};

export default InfoNotification;
