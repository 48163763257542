import App from "./App";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";

import "nprogress/nprogress.css";
import { SidebarProvider } from "./contexts/SidebarContext";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import store from "./store";
import SuspenseLoader from "./components/SuspenseLoader";
let persistor = persistStore(store);

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <PersistGate loading={<SuspenseLoader />} persistor={persistor}>
        <SidebarProvider>
          <App />
        </SidebarProvider>
      </PersistGate>
    </Provider>
  </HelmetProvider>,
  document.getElementById("root")
);
