import { createSlice } from "@reduxjs/toolkit";

const baseAccountObj = {
  fullName: "",
  userType: "",
  userName: null,
  token: null,
  privilege: 7,
  attempts: 0,
  status: "",
};
export const accountSlice = createSlice({
  name: "account",
  initialState: { ...baseAccountObj },
  reducers: {
    setLogin: (state, action) => {
      state.fullName = action.payload.fullName;
      state.userType = action.payload.userType;
      state.userName = action.payload.userName;
      state.token = action.payload.token;
      state.privilege = action.payload.privilege;
      state.attempts = 0;
    },
    setLogout: (state, action) => {
      let newState = { ...baseAccountObj };
      return newState;
    },
    addAttempt: (state, action) => {
      state.attempts = Number(state.attempts) + 1;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setUserInfo: (state, action) => {
      state.fullName = action.payload.fullName;
      state.userType = action.payload.userType;
      state.privilege = action.payload.privilege;
      state.status = action.payload.status;
    },
    emptyAllAccountSlice: (state, action) => {
      state = { ...baseAccountObj };
    },
  },
});

export const {
  setLogin,
  setLogout,
  addAttempt,
  setStatus,
  setUserInfo,
} = accountSlice.actions;

export const accountSelect = (state) => state.accountData;
export const isLoggedSelect = (state) => state.accountData.token;

export default accountSlice.reducer;
