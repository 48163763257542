import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router";

import { useIdleTimer } from "react-idle-timer";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import axios from "src/utils/axios-interface";
import axiosOriginal from "axios";

import { getSocketClient } from "src/utils/socket-client";
import useRefMounted from "src/hooks/useRefMounted";
import { accountSelect } from "src/slices/account-slice";
import { STATUS_TYPES, USER_TYPES, MISC_SETTINGS } from "src/utils/info-base";

import {
  selectNotifications,
  pushMsgToCurrentChat,
  setChatActiveUsers,
  setChatIsRegistered,
  selectChat,
  selectMessages,
  incrementMessagesUnseen,
  setLastIncomingChat,
  selectUserSettings,
  setMessagesRinged,
} from "src/slices/common-slice";
import {
  getOrderApprovals,
  getDepositApprovals,
  getUsersList,
  getOrdersInfo,
  getDepositsInfo,
  getAgentRateInfo,
  getDashboardInfo,
  getCountriesInfo,
  getRateInfo,
  getPaymentsInfo,
  getSenderOrdersInfo,
  getSenderRateInfo,
  getSenderAccountsInfo,
  getSenderDashboard,
  getSenderDepositsInfo,
  getSenderPayments,
  getSenderPaymentStat,
  getDistOrdersInfo,
  getDistDepositsInfo,
  getDistDashboard,
  getDistDepositCurrencies,
  getDistPayments,
  getDistPaymentStat,
  getDistBalanceInfo,
  getActiveCountries,
  getBenCoAddCurr,
  getBeneficiaryCountryCity,
  getNotifications,
  getUserSettings,
  getUnseenMessages,
  getKvPair,
  logOut,
  closeAccount,
  getUserInfo,
  getSimpleRate,
} from "src/utils/action-center";

import Zoom from "@material-ui/core/Zoom";
import devLog from "src/utils/dev-log";

const FeedInitialData = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const isMountedRef = useRefMounted();
  const selectedAccount = useSelector(accountSelect);
  const { idleTimeOut, msgSound, chatSound } = useSelector(selectUserSettings);
  const { data } = useSelector(selectNotifications);
  const {
    data: dataMessage,
    unseen,
    ringed,
    cleared,
  } = useSelector(selectMessages);

  const { data: chatData, currentUser, lastInChat } = useSelector(selectChat);
  const [isLoading, setIsLoading] = useState(true);
  // const msgChat = new Audio(MISC_SETTINGS.audioChatURL);
  // const msgAudio = new Audio(MISC_SETTINGS.audioMsgURL);
  const showToast = (typ = "error", msg = "Please try again later.") => {
    enqueueSnackbar(msg, {
      variant: typ,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      TransitionComponent: Zoom,
    });
  };

  axios.all = axiosOriginal.all;
  axios.spread = axiosOriginal.spread;
  axios.defaults.headers.common.Authorization = `Bearer ${selectedAccount.token}`;
  const socket = getSocketClient();
  const userType = selectedAccount.userType;
  const audio = new Audio(MISC_SETTINGS.audioNfxURL);

  const handleOnIdle = (event) => {
    logOut();
    history.push("/logout");
  };

  const handleOnActive = (event) => {};

  const handleOnAction = (event) => {};

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: idleTimeOut,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
    crossTab: {
      emitOnAllTabs: true,
    },
  });

  const takeAction = (nfxType) => {
    switch (nfxType) {
      case STATUS_TYPES.nfxType.NEW_ORDER:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          getOrdersInfo();
          getOrderApprovals();
          try {
            audio.play();
          } catch (errorAudio) {
            devLog("Err playing audio:", errorAudio);
          }
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          //
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          //
        }
        break;
      case STATUS_TYPES.nfxType.ORDER_APPROVED:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          getOrderApprovals();
          getOrdersInfo();
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          //
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          getDistOrdersInfo();
          getDistDashboard();
        }
        break;
      case STATUS_TYPES.nfxType.ORDER_CREATED_OFFICE:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          //
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          getSenderOrdersInfo();
          getSenderDashboard();
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          getDistOrdersInfo();
          getDistDashboard();
        }
        break;
      case STATUS_TYPES.nfxType.ORDER_EDIT_REQ:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          getOrderApprovals();
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          //
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          //
        }
        break;
      case STATUS_TYPES.nfxType.ORDER_EDIT_APPROVED:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          //
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          getSenderOrdersInfo();
          getSenderDashboard();
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          getDistOrdersInfo();
          getDistDashboard();
        }
        break;
      case STATUS_TYPES.nfxType.ORDER_EDIT_REJECTED:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          //
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          getSenderOrdersInfo();
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          //
        }
        break;
      case STATUS_TYPES.nfxType.ORDER_RETURN_REQ:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          getOrdersInfo();
          getOrderApprovals();
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          getSenderOrdersInfo();
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          //
        }
        break;
      case STATUS_TYPES.nfxType.ORDER_CANCEL_REQ:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          getOrdersInfo();
          getOrderApprovals();
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          //
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          //
        }
        break;
      case STATUS_TYPES.nfxType.ORDER_CANCEL_REJECTED:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          //
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          getSenderOrdersInfo();
          getSenderDashboard();
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          //
        }
        break;
      case STATUS_TYPES.nfxType.ORDER_SENT:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          getOrderApprovals();
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          getSenderOrdersInfo();
          getSenderAccountsInfo();
          getSenderDashboard();
          getSenderPayments();
          getSenderPaymentStat();
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          getDistPayments();
          getDistPaymentStat();
          getDistDashboard();
          getDistBalanceInfo();
        }
        break;
      case STATUS_TYPES.nfxType.ORDER_RETURNED:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          //
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          getSenderOrdersInfo();
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          //
        }
        break;
      case STATUS_TYPES.nfxType.ORDER_CANCELLED:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          getOrdersInfo();
          getDashboardInfo();
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          getSenderOrdersInfo();
          getSenderAccountsInfo();
          getSenderDashboard();
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          getDistOrdersInfo();
          getDistBalanceInfo();
          getDistDashboard();
        }
        break;
      case STATUS_TYPES.nfxType.NEW_DEPOSIT:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          getDepositsInfo();
          getDepositApprovals();
          getDashboardInfo();
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          //
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          //
        }
        break;
      case STATUS_TYPES.nfxType.NEW_DEPOSIT_OFFICE:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          getDepositsInfo();
          getDepositApprovals();
          getDashboardInfo();
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          getSenderDepositsInfo();
          getSenderDashboard();
          getSenderAccountsInfo();
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          getDistDepositsInfo();
          getDistDashboard();
          getDistBalanceInfo();
        }
        break;
      case STATUS_TYPES.nfxType.DEPOSIT_APPROVED:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          //
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          getSenderDepositsInfo();
          getSenderDashboard();
          getSenderAccountsInfo();
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          getDistDepositsInfo();
          getDistDashboard();
          getDistBalanceInfo();
        }
        break;
      case STATUS_TYPES.nfxType.DEPOSIT_REJECTED:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          //
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          getSenderDepositsInfo();
          getSenderDashboard();
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          getDistDepositsInfo();
          getDistDashboard();
        }
        break;
      case STATUS_TYPES.nfxType.NEW_PAYMENT:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          //
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          getSenderPayments();
          getSenderPaymentStat();
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          getDistPayments();
          getDistPaymentStat();
        }
        break;
      case STATUS_TYPES.nfxType.ACTIVE_COUNTRIES:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          getActiveCountries();
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
        }
        break;
      case STATUS_TYPES.nfxType.BENEFICIARY_COUNTRIES:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          //
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          getActiveCountries();
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
        }
        break;
      case STATUS_TYPES.nfxType.RATE:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          getSimpleRate();
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          getSenderRateInfo();
          getSimpleRate();
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          //
        }
        break;
      case STATUS_TYPES.nfxType.KV_PAIR:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          //
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          getKvPair(STATUS_TYPES.kvType.SIMPLE_RATE_HEADER);
          getKvPair(STATUS_TYPES.kvType.SIMPLE_RATE_FOOTER);
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          //
        }
        break;
      case STATUS_TYPES.nfxType.ACCOUNT_INFO:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          getSenderAccountsInfo();
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
        }
        break;
      case STATUS_TYPES.nfxType.USER_INFO:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          getUserInfo();
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          getUserInfo();
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          getUserInfo();
        }
        break;
      case STATUS_TYPES.nfxType.TELEGRAM_ACCESS:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          getUsersList();
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          //
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          //
        }
        break;
      case STATUS_TYPES.nfxType.LOG_OUT:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          logOut();
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          logOut();
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          logOut();
        }
        break;
      case STATUS_TYPES.nfxType.LOG_OUT_ALL:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          logOut();
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          logOut();
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          logOut();
        }
        break;
      case STATUS_TYPES.nfxType.CLOSED_ACCOUNT_INFO:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          getUsersList();
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          //
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          //
        }
        break;
      case STATUS_TYPES.nfxType.CLOSE_ACCOUNT:
        if (userType === USER_TYPES.OFFICE || userType === USER_TYPES.ADMIN) {
          closeAccount();
        } else if (
          userType === USER_TYPES.AGENT_SENDER ||
          userType === USER_TYPES.AGENT_BOTH
        ) {
          closeAccount();
        } else if (userType === USER_TYPES.AGENT_DISTRIBUTOR) {
          closeAccount();
        }
        break;
      default:
        break;
    }

    getNotifications();
  };

  useEffect(() => {
    if (isMountedRef.current && socket) {
      if (selectedAccount.token) {
        socket.on("welcome", () => {
          socket.emit("reg", selectedAccount.token, (res) => {
            if (res === "success") {
              dispatch(setChatIsRegistered(true));
            }
          });
        });
      }

      socket.on("nfx", (nfxType) => {
        takeAction(nfxType);
      });
      socket.on("newMsg", (newMsg) => {
        if (newMsg) {
          dispatch(pushMsgToCurrentChat(newMsg));
          dispatch(setLastIncomingChat(newMsg));
        }
      });
      socket.on("updateActive", (actUsr) => {
        dispatch(setChatActiveUsers(actUsr));
      });
    }
  }, []);

  useEffect(() => {
    if (isMountedRef.current && selectedAccount.token) {
      if (!data.length) {
        getNotifications();
      }

      if (!dataMessage.length) {
        getUnseenMessages();
      }
    }
  }, [selectedAccount.token]);
  useEffect(() => {
    let lastChat = lastInChat;
    if (isMountedRef.current && lastChat) {
      if (!pathname.includes("chat") || !(lastChat.fromUser === currentUser)) {
        dispatch(incrementMessagesUnseen());
      }
    }
  }, [lastInChat]);

  useEffect(() => {
    if (isMountedRef.current) {
      if (cleared) {
        return;
      }
      if (dataMessage && dataMessage.length) {
        if (unseen && !ringed && msgSound === "on") {
          // try {
          //   // msgAudio.play();
          //   devLog('Msg audio played');
          //   dispatch(setMessagesRinged(true));
          // } catch (errorPlayingMsgAudio) {
          //   devLog('err playing audio', errorPlayingMsgAudio);
          // }
        }
      }
    }
  }, [dataMessage, unseen]);

  return <>{children}</>;
};

export default FeedInitialData;
