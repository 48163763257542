import { useContext } from "react";
import { useHistory } from "react-router";
import { Box, Hidden, IconButton, Tooltip } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import MenuTwoToneIcon from "@material-ui/icons/MenuTwoTone";
import { SidebarContext } from "src/contexts/SidebarContext";
import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import KeyboardBackspaceTwoToneIcon from "@material-ui/icons/KeyboardBackspaceTwoTone";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";

import HeaderNotifications from "src/layouts/SidebarLayout/Header/Buttons/Notifications/notifications";
import AdvUsersListAutoComplete from "src/components/UsersListAutoComplete/advanced-users-list-auto-complete";
import HeaderUserbox from "./Userbox";

const UsableTheme = () => {
  const themeN = useTheme();
  return themeN;
};

function Header() {
  const HeaderWrapper = styled(Box)`
    height: 88px;
    color: #6e759f;
    padding: 16px;
    right: 0;
    z-index: 100;
    background-color: ${UsableTheme().palette.background.default};
    box-shadow: 0px 2px 3px rgba(159, 162, 191, 0.18),
      0px 1px 1px rgba(159, 162, 191, 0.32);
    position: fixed;
    justify-content: space-between;
    width: 100%;
    /* @media (min-width: 1280px) {
  left: 280px;
  width: auto;
} */
  `;

  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const history = useHistory();
  const handleBack = () => {
    // TODO2 , don't allow back to /login
    history.goBack();
  };
  const gotoOrderApprovals = () => {
    history.push(`/manage/approvals/order`);
  };
  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Box display="flex" alignContent="center">
        {/* <Hidden lgUp> */}
        <Tooltip arrow title="Menu">
          <IconButton color="primary" onClick={toggleSidebar}>
            {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
          </IconButton>
        </Tooltip>
        {/* </Hidden> */}
        <Tooltip arrow title="Back">
          <IconButton color="primary" onClick={handleBack}>
            <KeyboardBackspaceTwoToneIcon />
          </IconButton>
        </Tooltip>

        <Hidden smDown>
          <Tooltip arrow title="Goto Order Approvals">
            <IconButton
              color="primary"
              onClick={gotoOrderApprovals}
              style={{ marginLeft: 30 }}
            >
              <PlaylistAddCheckIcon />
            </IconButton>
          </Tooltip>
        </Hidden>

        <Hidden smDown>
          <Box style={{ minWidth: 500, marginLeft: 8 }}>
            <AdvUsersListAutoComplete
              mainLink="/manage/users/view/"
              label="Search Agents"
              filterTypeNot={[
                {
                  key: "userType",
                  value: "office",
                },
              ]}
            />
          </Box>
        </Hidden>
      </Box>
      <Box display="flex" alignItems="center">
        <HeaderNotifications />
        <HeaderUserbox />
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
