import PollTwoToneIcon from "@material-ui/icons/PollTwoTone";
import AccountBalanceTwoToneIcon from "@material-ui/icons/AccountBalanceTwoTone";
import AccountBalanceWalletTwoToneIcon from "@material-ui/icons/AccountBalanceWalletTwoTone";
import WorkTwoToneIcon from "@material-ui/icons/WorkTwoTone";
import AssignmentIndTwoToneIcon from "@material-ui/icons/AssignmentIndTwoTone";
import ReceiptTwoToneIcon from "@material-ui/icons/ReceiptTwoTone";
import ExitToAppTwoToneIcon from "@material-ui/icons/ExitToAppTwoTone";
import AddBoxTwoToneIcon from "@material-ui/icons/AddBoxTwoTone";
import NotificationsTwoToneIcon from "@material-ui/icons/NotificationsTwoTone";
import PersonOutlineTwoToneIcon from "@material-ui/icons/PersonOutlineTwoTone";
import PersonAddTwoToneIcon from "@material-ui/icons/PersonAddTwoTone";
import SettingsTwoToneIcon from "@material-ui/icons/SettingsTwoTone";
import LocationCityTwoToneIcon from "@material-ui/icons/LocationCityTwoTone";
import PublicTwoToneIcon from "@material-ui/icons/PublicTwoTone";
import RotateRightTwoToneIcon from "@material-ui/icons/RotateRightTwoTone";
import VpnKeyTwoToneIcon from "@material-ui/icons/VpnKeyTwoTone";
import AssignmentTurnedInTwoToneIcon from "@material-ui/icons/AssignmentTurnedInTwoTone";
import ReorderTwoToneIcon from "@material-ui/icons/ReorderTwoTone";
import ListAltTwoToneIcon from "@material-ui/icons/ListAltTwoTone";
import PowerSettingsNewTwoToneIcon from "@material-ui/icons/PowerSettingsNewTwoTone";
import SettingsApplicationsTwoToneIcon from "@material-ui/icons/SettingsApplicationsTwoTone";
import AssessmentTwoToneIcon from "@material-ui/icons/AssessmentTwoTone";
import MoneyTwoToneIcon from "@material-ui/icons/MoneyTwoTone";
import PostAddTwoToneIcon from "@material-ui/icons/PostAddTwoTone";
import LocalAtmTwoToneIcon from "@material-ui/icons/LocalAtmTwoTone";
import WorkIcon from "@material-ui/icons/Work";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import MessageIcon from "@material-ui/icons/Message";
import SendIcon from "@material-ui/icons/Send";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import HelpIcon from "@material-ui/icons/Help";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PaymentIcon from '@material-ui/icons/Payment';

const menuItems = [
  // AGENT SENDER
  {
    heading: "Agent Sender",
    privilege: [4, 5],
    items: [{
        name: "Dashboard",
        icon: PollTwoToneIcon,
        link: "/agent/s/dashboard",
      },
      {
        name: "Orders",
        icon: PollTwoToneIcon,
        link: "/agent/s/orders",
        items: [{
            name: "New Order",
            icon: AddBoxTwoToneIcon,
            link: "/agent/s/orders/new",
          },
          {
            name: "View Orders",
            icon: WorkTwoToneIcon,
            link: "/agent/s/orders",
          },
        ],
      },
      {
        name: "Deposit",
        icon: AccountBalanceWalletTwoToneIcon,
        link: "/agent/s/deposits",
        items: [{
            name: "New Deposit",
            icon: AddBoxTwoToneIcon,
            link: "/agent/s/deposits/new",
          },
          {
            name: "View Deposits",
            icon: AccountBalanceTwoToneIcon,
            link: "/agent/s/deposits/view",
          },
        ],
      },
      {
        name: "Payments",
        icon: MoneyTwoToneIcon,
        link: "/agent/s/payments",
        items: [{
          name: "View Payments",
          icon: AccountBalanceTwoToneIcon,
          link: "/agent/s/payments",
        }, ],
      },
    ],
  },
  // AGENT DISTRIBUTOR
  {
    heading: "Agent Dist.",
    privilege: [4, 6],
    items: [{
        name: "Dashboard",
        icon: PollTwoToneIcon,
        link: "/agent/d/dashboard",
      },
      {
        name: "Orders",
        icon: PollTwoToneIcon,
        link: "/agent/d/orders",
        items: [{
            name: "Active Orders",
            icon: ListAltTwoToneIcon,
            link: "/agent/d/orders/active",
          },
          {
            name: "All Orders",
            icon: WorkTwoToneIcon,
            link: "/agent/d/orders",
          },
        ],
      },
      {
        name: "Deposit",
        icon: AccountBalanceWalletTwoToneIcon,
        link: "/agent/d/deposits",
        items: [{
            name: "New Deposit",
            icon: AddBoxTwoToneIcon,
            link: "/agent/d/deposits/new",
          },
          {
            name: "View Deposits",
            icon: AccountBalanceTwoToneIcon,
            link: "/agent/d/deposits",
          },
        ],
      },
      {
        name: "Payments",
        icon: MoneyTwoToneIcon,
        link: "/agent/d/payments",
        items: [{
          name: "View Payments",
          icon: AccountBalanceTwoToneIcon,
          link: "/agent/d/payments",
        }, ],
      },
    ],
  },
  // Manage
  {
    heading: "OFFICE",
    privilege: [1, 2, 3],
    items: [{
        name: "Dashboard",
        icon: PollTwoToneIcon,
        link: "/manage/dashboard",
      },
      {
        name: "Approvals",
        icon: AssignmentTurnedInTwoToneIcon,
        link: "/manage/approvals",
        items: [{
            name: "Order Approval",
            icon: PlaylistAddCheckIcon,
            // badge: String(store.getState().officeData.orderApprovals.length),
            link: "/manage/approvals/order",
          },
          {
            name: "Re-Assign Order",
            icon: FlipCameraAndroidIcon,
            link: "/manage/approvals/reassign",
          },
          {
            name: "Deposit Approval",
            icon: AccountBalanceWalletTwoToneIcon,
            // badge: String(store.getState().officeData.depositApprovals.length),
            link: "/manage/approvals/deposit",
          },
        ],
      },
      {
        name: "Deposits",
        icon: AccountBalanceWalletTwoToneIcon,
        link: "/manage/deposits",

        items: [{
            name: "New Deposits",
            icon: AddBoxTwoToneIcon,
            link: "/manage/deposits/new",
          },
          // {
          //   name: "Manage Deposits",
          //   icon: AccountBalanceWalletTwoToneIcon,
          //   link: "/manage/deposits",
          // },
        ],
      },
      {
        name: "Orders",
        icon: ReorderTwoToneIcon,
        link: "/manage/orders",

        items: [{
            name: "New Order",
            icon: AddBoxTwoToneIcon,
            link: "/manage/orders/new",
          },
          {
            name: "Active Orders",
            icon: WorkIcon,
            link: "/manage/orders/active",
          },
          // {
          //   name: "Manage All Orders",
          //   icon: ReorderTwoToneIcon,
          //   link: "/manage/orders",
          // },
        ],
      },
      {
        name: "Users/Agents",
        icon: PersonOutlineTwoToneIcon,
        link: "/manage/users",
        items: [{
            name: "New User",
            icon: PersonAddTwoToneIcon,
            link: "/manage/users/new",
          },
          {
            name: "View All Users",
            icon: AssignmentIndTwoToneIcon,

            link: "/manage/users",
          },
        ],
      },
      {
        name: "Rates",
        icon: LocalAtmTwoToneIcon,
        link: "/manage/simple-rate",
        items: [{
          name: "Manage Rates",
          icon: LocalAtmTwoToneIcon,
          link: "/manage/simple-rate",
        }, ],
      },
      // {
      //   name: "Salary / Profits",
      //   icon: AttachMoneyIcon,
      //   link: "/manage/message",
      //   items: [
      //     {
      //       name: "Manage Salaries",
      //       icon: AttachMoneyIcon,
      //       link: "/manage/salary-groups",
      //     },

      //   ],
      // },
      {
        name: "Message",
        icon: MessageIcon,
        link: "/manage/message",
        items: [{
            name: "Send Message",
            icon: SendIcon,
            link: "/manage/message/new",
          },
          {
            name: "View Messages",
            icon: MailOutlineIcon,
            link: "/manage/messages",
          },
        ],
      },
      {
        name: "More",
        icon: MoreHorizIcon,
        link: "/manage/message",
        items: [{
            name: "Manage Salaries",
            icon: AttachMoneyIcon,
            link: "/manage/salary-groups",
          },
          {
            name: "Balance Info.",
            icon: PaymentIcon,
            link: "/manage/balance-info",
          },
        ],
      },
    ],
  },
  // Super Admin
  {
    heading: "Admin Settings",
    privilege: [1],
    items: [{
        name: "Payments",
        icon: MoneyTwoToneIcon,
        link: "/admin/payments",
        items: [
          // {
          //   name: "View Agents Payment",
          //   icon: ReceiptTwoToneIcon,
          //   link: "/admin/payments/agents",
          // },
          {
            name: "New Payment",
            icon: PostAddTwoToneIcon,
            link: "/admin/payments/new",
          },
          {
            name: "View All Payments",
            icon: AccountBalanceTwoToneIcon,
            link: "/admin/payments",
          },
        ],
      },
      {
        name: "Config",
        icon: SettingsApplicationsTwoToneIcon,
        link: "/admin/settings",

        items: [
          // {
          //   name: 'Rates',
          //   icon: TrendingUpTwoToneIcon,
          //   link: '/admin/settings/rates'
          // },
          {
            name: "Cities",
            icon: LocationCityTwoToneIcon,
            link: "/admin/settings/cities",
          },
          {
            name: "Beneficiary Countries",
            icon: RotateRightTwoToneIcon,
            link: "/admin/settings/beneficiary-countries",
          },
          {
            name: "Countries",
            icon: PublicTwoToneIcon,
            link: "/admin/settings/countries",
          },
          {
            name: "Rules",
            icon: SettingsApplicationsIcon,
            link: "/admin/settings/rules",
          },
          // {
          //   name: "Manage Salaries",
          //   icon: AttachMoneyIcon,
          //   link: "/admin/settings/salary-groups",
          // },
          // deprecated
          // {
          //   name: "Commission",
          //   icon: AttachMoneyIcon,
          //   link: "/admin/settings/profit-percent",
          // },
        ],
      },
      {
        name: "Reports",
        icon: AssessmentTwoToneIcon,
        link: "/admin/report",
        items: [
          {
            name: "All Orders",
            icon: ReorderTwoToneIcon,
            link: "/manage/orders",
          },
          {
            name: "All Deposits",
            icon: AccountBalanceWalletTwoToneIcon,
            link: "/manage/deposits",
          },
          {
            name: "Auto Report",
            icon: PersonAddTwoToneIcon,
            link: "/admin/report/auto",
          },
          // {
          //   name: "Manual",
          //   icon: AssignmentIndTwoToneIcon,
          //   link: "/admin/report/manual",
          // },
        ],
      },
    ],
  },
  // common
  {
    heading: "",
    privilege: [1, 2, 3, 4, 5, 6],
    items: [{
        name: "Notifications",
        icon: NotificationsTwoToneIcon,
        link: "/notifications",
      },
      {
        name: "My Settings",
        icon: SettingsTwoToneIcon,
        link: "/agent/s/settings",
        items: [{
          name: "Change Password",
          icon: VpnKeyTwoToneIcon,
          link: "/change-password",
        }, ],
      },

      // {
      //   name: "Help",
      //   icon: HelpIcon,
      //   link: "/help",
      // },
      {
        name: "Logout",
        icon: ExitToAppTwoToneIcon,
        link: "/logout",
      },

      {
        name: "___",
        // icon: ExitToAppTwoToneIcon,
        link: "#",
      },
      {
        name: "Maintenance Area",
        icon: PowerSettingsNewTwoToneIcon,
        link: "/close",
      },
    ],
  },
];

export default menuItems;