const devLog = (title, toLog) => {
  if (process.env.NODE_ENV === 'development') {
    if (title === 'marker') {
      console.log('******* DEV-Log MARKER ********* ');
    } else {
      console.log(title, toLog);
    }
  }
};

export default devLog;
