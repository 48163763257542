import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import { Box } from "@material-ui/core";

import Sidebar from "./Sidebar";
import Header from "./Header";

const MainWrapper = styled(Box)`
  flex: 1 1 auto;
  display: flex;
  height: 100%;

  /* @media (min-width: 1280px) {
    padding-left: 280px;
  } */
`;
const MainContent = styled(Box)`
  margin-top: 88px;
  flex: 1 1 auto;
  overflow: auto;
`;

const SidebarLayout = ({ children }) => {
  return (
    <>
      <Sidebar />
      <MainWrapper>
        <Header />
        <MainContent>{children}</MainContent>
      </MainWrapper>
    </>
  );
};

SidebarLayout.propTypes = {
  children: PropTypes.node,
};

export default SidebarLayout;
