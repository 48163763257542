import store from "src/store";

import socketClientIO from "socket.io-client";
import { MISC_SETTINGS } from "./info-base";
const token = store.getState().accountData;

const socket = socketClientIO(MISC_SETTINGS.getBaseUrl(), {
  auth: {
    token: token,
  },
});

export const getSocketClient = () => socket;
